import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import fadeInStyles from './FadeIn.module.css'
import inputStyles from './Input.module.css'
import styles from './InputEmail.module.css'

export type InputEmailProps = Slice<
	SliceType.input_email,
	{
		name: string
		label: string
		placeholder: string
		required: boolean
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
	}
>

export function InputEmail(props: InputEmailProps): string {
	const { primary } = props
	const { name, label, placeholder, required = false, column_span_small = '6/6', column_span_large = '6/6' } = primary

	return /* HTML */ `
		<label
			class="${clsx(
				styles.Main,
				inputStyles.Main,
				fadeInStyles.Main,
				getColumnSpanClasses(column_span_small, column_span_large)
			)}"
			><span class="${inputStyles.Label}">${label}${required ? '*' : ''}</span>
			<input
				class="${inputStyles.Input}"
				type="email"
				name="${name}"
				placeholder="${placeholder}"
				${required ? 'required' : ''}
			/>
		</label>
	`
}
