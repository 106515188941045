import clsx from 'clsx'

import inputStyles from '../components/Input.module.css'

export function getColumnSpanClasses(columnSpanSmall: string, columnSpanLarge: string): string {
	return clsx(
		columnSpanSmall === '6/6' && inputStyles.GridSixSixthSmall,
		columnSpanSmall === '5/6' && inputStyles.GridFiveSixthSmall,
		columnSpanSmall === '4/6' && inputStyles.GridFourSixthSmall,
		columnSpanSmall === '3/6' && inputStyles.GridThreeSixthSmall,
		columnSpanSmall === '2/6' && inputStyles.GridTwoSixthSmall,
		columnSpanSmall === '1/6' && inputStyles.GridOneSixthSmall,
		columnSpanLarge === '6/6' && inputStyles.GridSixSixthLarge,
		columnSpanLarge === '5/6' && inputStyles.GridFiveSixthLarge,
		columnSpanLarge === '4/6' && inputStyles.GridFourSixthLarge,
		columnSpanLarge === '3/6' && inputStyles.GridThreeSixthLarge,
		columnSpanLarge === '2/6' && inputStyles.GridTwoSixthLarge,
		columnSpanLarge === '1/6' && inputStyles.GridOneSixthLarge
	)
}
