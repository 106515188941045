#define GLSLIFY 1
uniform float time;
uniform float size;
uniform float speed;
varying vec2 vUv;

void main() {
	vUv = uv;
	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
