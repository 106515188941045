import {
	FilledContentRelationshipField,
	FilledImageFieldImage,
	RichTextField,
	Slice,
	SliceZone
} from '@prismicio/types'
import { CustomType, Lang, SliceType } from '../const'
import styles from './Accordion.module.css'
import { asHTML } from '@prismicio/helpers'
import { ResponsiveImage } from './ResponsiveImage'
import linkResolver from '../utils/linkResolver'
import clsx from 'clsx'
import fadeInStyles from './FadeIn.module.css'
import { textSerializer } from '../utils/textSerializer'

export type AccordionItemProps = Slice<
	SliceType.accordion_item,
	{
		title?: RichTextField
		text?: RichTextField
	},
	{
		image?: FilledImageFieldImage
		eyebrow?: string
		title?: RichTextField
		text?: RichTextField
	}
>

export type AccordionProps = Slice<
	SliceType.accordion,
	{
		accordion?: FilledContentRelationshipField<
			CustomType.accordion,
			Lang,
			{
				body: SliceZone<AccordionItemProps>
			}
		>
	}
>

export function Accordion(props: AccordionProps): string {
	const {
		primary: { accordion }
	} = props

	if (!accordion?.data) return ''

	const { body = [] } = accordion.data

	return /* HTML */ `<div class="${styles.Main}">
		<div class="${clsx(styles.Inner, fadeInStyles.Main)}">
			${body
				.map((slice) => {
					const {
						primary: { title, text },
						items
					} = slice

					return /* HTML */ `<div class="${styles.AccordionItem}">
						<button class="${styles.Header}">
							${title ? `<span class="${styles.Title}">${asHTML(title)}</span>` : ''}
							<svg class="${styles.Arrow}" viewBox="0 0 8 42" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M3.64645 41.3536C3.84171 41.5488 4.15829 41.5488 4.35355 41.3536L7.53553 38.1716C7.7308 37.9763 7.7308 37.6597 7.53553 37.4645C7.34027 37.2692 7.02369 37.2692 6.82843 37.4645L4 40.2929L1.17157 37.4645C0.976311 37.2692 0.659728 37.2692 0.464466 37.4645C0.269204 37.6597 0.269204 37.9763 0.464466 38.1716L3.64645 41.3536ZM4.5 41L4.5 2.18557e-08L3.5 -2.18557e-08L3.5 41L4.5 41Z"
								/>
							</svg>
						</button>
						<div class="${styles.Body}">
							${text
								? `<div class="${styles.Text}">${asHTML(text, linkResolver, textSerializer)}</div>`
								: ''}
							${items
								? `<div class="${styles.Group}">${items
										.map((item) => {
											const { image, eyebrow, title, text } = item
											return /* HTML */ `<div class="${styles.Item}">
												<div class="${styles.ItemHeader}">
													${image
														? `<div class="${styles.ItemImage}">${ResponsiveImage(
																image
														  )}</div>`
														: ''}
												</div>
												<div class="${styles.ItemContent}">
													${eyebrow
														? `<div class="${styles.ItemEyebrow}">${eyebrow}</div>`
														: ''}
													${title
														? `<div class="${styles.ItemTitle}">${asHTML(title)}</div>`
														: ''}
													${text
														? `<div class="${styles.ItemText}">${asHTML(
																text,
																linkResolver,
																textSerializer
														  )}</div>`
														: ''}
												</div>
											</div>`
										})
										.join('')}</div>`
								: ''}
						</div>
					</div>`
				})
				.join('')}
		</div>
	</div>`
}
