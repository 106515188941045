import { asHTML, HTMLMapSerializer } from '@prismicio/helpers'
import { RichTextField, Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import linkResolver from '../utils/linkResolver'
import fadeInStyles from './FadeIn.module.css'
import styles from './InputCheckbox.module.css'

export type InputCheckboxProps = Slice<
	SliceType.input_checkbox,
	{
		name: string
		label: RichTextField
		required: boolean
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
	}
>

export function InputCheckbox(props: InputCheckboxProps): string {
	const { primary } = props
	const { name, label, required = false, column_span_small = '6/6', column_span_large = '6/6' } = primary

	const serializer: HTMLMapSerializer = {
		paragraph: ({ children }) => (children ? children : '')
	}

	return /* HTML */ `
		<label
			class="${clsx(styles.Main, fadeInStyles.Main, getColumnSpanClasses(column_span_small, column_span_large))}"
		>
			<svg class="${styles.Svg}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
				<path d="M512 512V0H0v512h512ZM492.308 19.692v472.616H19.692V19.692h472.616Z" />
				<path
					class="${styles.PathCheckmark}"
					d="M91.582 245.813 216.993 368.31l203.513-203.814-13.935-13.915L216.82 340.614 105.341 231.726l-13.759 14.087Z"
				/>
			</svg>
			<span class="${styles.LabelText}">${asHTML(label, linkResolver, serializer)}</span>
			<input class="${styles.Input}" type="checkbox" name="${name}" ${required ? 'required' : ''} />
		</label>
	`
}
