import { getSrcSet } from '../utils/getSrcSet'
import styles from './ResponsiveImage.module.css'
import { IMAGE_SIZES } from '../const'
import { FilledImageFieldImage } from '@prismicio/types/src/value/image'

export type ResponsiveImage = FilledImageFieldImage & {
	loading?: 'lazy' | 'eager'
}

export function ResponsiveImage(props: ResponsiveImage): string {
	const { url, dimensions, alt, loading = 'lazy' } = props

	if (!url) return ''

	const width = dimensions?.width || 1
	const height = dimensions?.height || 1

	const aspect = width / height
	const srcSet = getSrcSet(url, IMAGE_SIZES)

	return /* HTML */ `
		<div class="${styles.Main}" style="padding-bottom: ${(1 / aspect) * 100}%;">
			<img class="${styles.Image}" alt="${alt}" data-srcset="${srcSet}" loading="${loading}" />
		</div>
	`
}
