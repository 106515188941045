import { PrismicDocumentWithUID, Slice, SliceZone } from '@prismicio/types'
import { FilledContentRelationshipField } from '@prismicio/types/src/value/contentRelationship'
import clsx from 'clsx'

import { CustomType, EmailToFormID, Lang, SliceType } from '../const'
import fadeInStyles from './FadeIn.module.css'
import { FieldDescription, FieldDescriptionProps } from './FieldDescription'
import { FieldSeparator, FieldSeparatorProps } from './FieldSeparator'
import styles from './Form.module.css'
import { InputCheckbox, InputCheckboxProps } from './InputCheckbox'
import { InputDate, InputDateProps } from './InputDate'
import { InputEmail, InputEmailProps } from './InputEmail'
import { InputFile, InputFileProps } from './InputFile'
import { InputNumber, InputNumberProps } from './InputNumber'
import { InputPhone, InputPhoneProps } from './InputPhone'
import { InputRadio, InputRadioProps } from './InputRadio'
import { InputSelect, InputSelectProps } from './InputSelect'
import { InputText, InputTextProps } from './InputText'
import { InputTextarea, InputTextareaProps } from './InputTextarea'

export type FormSliceProps = Slice<
	SliceType.form,
	{
		form?: FilledContentRelationshipField
		success_page?: FilledContentRelationshipField
		prefilled_value?: string
		prefilled_date?: string
		important_selected_option?: string
		important_select_max_number?: number
	}
>

export type FormDocumentProps = PrismicDocumentWithUID<
	{
		uid: string
		email: string
		submit_label: string
		body?: SliceZone<
			| FieldDescriptionProps
			| FieldSeparatorProps
			| InputCheckboxProps
			| InputDateProps
			| InputEmailProps
			| InputFileProps
			| InputNumberProps
			| InputPhoneProps
			| InputRadioProps
			| InputTextProps
			| InputTextareaProps
			| InputSelectProps
		>
	},
	CustomType,
	Lang
>

export function Form(
	props: FormDocumentProps,
	optionalData: {
		redirectPath?: string
		preFilledValue?: string
		preFilledDate?: string
		importantSelectedOption?: string
		importantSelectMaxNumber?: number
	}
): string {
	const { uid, data } = props
	const { email, submit_label, body } = data
	const { redirectPath, preFilledValue, preFilledDate, importantSelectedOption, importantSelectMaxNumber } =
		optionalData

	const formID = EmailToFormID[email as keyof typeof EmailToFormID]
	const hasFileInput = body?.some((slice) => slice.slice_type === SliceType.input_file)
	const indexOfFirstInputText = body?.findIndex((slice) => slice.slice_type === SliceType.input_text)
	const indexOfFirstInputDate = body?.findIndex((slice) => slice.slice_type === SliceType.input_date)
	const indexOfFirstInputSelect = body?.findIndex((slice) => slice.slice_type === SliceType.input_select)

	return /* HTML */ `<div class="${clsx(styles.Main)}">
		${formID
			? /* HTML */ `<form
						class="${styles.Form}"
						name=${uid}
						accept-charset="utf-8"
						action="https://formspree.io/f/${formID}"
						method="post"
						${redirectPath ? `data-redirect-path="${redirectPath}"` : ''}
						${hasFileInput ? `enctype="multipart/form-data"` : ''}
					>
						<fieldset class="${styles.Fieldset}">
							${body
								?.map((slice, index) => {
									switch (slice.slice_type) {
										case SliceType.input_checkbox: {
											return InputCheckbox(slice)
										}
										case SliceType.input_date: {
											return InputDate(
												slice,
												index === indexOfFirstInputDate ? preFilledDate : undefined
											)
										}
										case SliceType.input_email: {
											return InputEmail(slice)
										}
										case SliceType.input_file: {
											return InputFile(slice)
										}
										case SliceType.input_number: {
											return InputNumber(slice)
										}
										case SliceType.input_phone: {
											return InputPhone(slice)
										}
										case SliceType.input_radio: {
											return InputRadio(slice)
										}
										case SliceType.input_text: {
											return InputText(
												slice,
												index === indexOfFirstInputText ? preFilledValue : undefined
											)
										}
										case SliceType.input_textarea: {
											return InputTextarea(slice)
										}
										case SliceType.field_description: {
											return FieldDescription(slice)
										}
										case SliceType.field_separator: {
											return FieldSeparator(slice)
										}
										case SliceType.input_select: {
											return InputSelect(
												slice,
												index === indexOfFirstInputSelect
													? { importantSelectedOption, importantSelectMaxNumber }
													: undefined
											)
										}
									}
								})
								.join('')}
							<input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission" />
						</fieldset>
						<button class="${clsx(styles.SubmitButton, fadeInStyles.Main)}" type="submit">
							${submit_label ?? 'Submit'}
						</button>
					</form>
					<div class="${styles.ErrorMessage}"></div>`
			: /* HTML */ `<div class="${styles.ErrorMessage}">
					The email address could not be assigned to any form.
			  </div>`}
	</div>`
}
