import { App } from '../app/App'
import { Controller } from '../const'
import styles from './Form.module.css'

export class FormController implements Controller {
	private readonly form: HTMLFormElement
	private readonly status: HTMLElement
	private readonly redirectPath: string

	constructor(
		private readonly node: HTMLElement,
		private readonly app: App
	) {
		this.onSubmit = this.onSubmit.bind(this)

		this.form = node.querySelector(`.${styles.Form}`) as HTMLFormElement
		this.status = node.querySelector(`.${styles.ErrorMessage}`) as HTMLElement

		this.redirectPath = this.form.dataset.redirectPath ?? '/'

		this.form?.addEventListener('submit', this.onSubmit)
	}

	async onSubmit(event: SubmitEvent): Promise<void> {
		if (!this.form) return
		event.preventDefault()

		try {
			const data = new FormData(this.form)

			const response = await fetch(this.form.action, {
				method: this.form.method,
				body: data,
				headers: {
					Accept: 'application/json'
				}
			})

			if (response.ok) {
				this.form.reset()

				this.app.router.push(this.redirectPath)
			} else {
				const data = await response.json()

				if (Array.isArray(data.errors)) {
					this.setStatus(data.errors.map((error: { message: string }) => error.message).join(', '))
				} else {
					this.setStatus(this.form.dataset?.error || 'error')
				}
			}
		} catch (error) {
			this.setStatus(this.form.dataset?.error || 'error')
		}
	}

	setStatus(message: string): void {
		if (this.status) {
			this.status.textContent = message
		}
	}

	dispose(): void {
		this.form?.removeEventListener('submit', this.onSubmit)
	}
}
