import { LinkType, RichTextField, RTAnyNode, RTLinkNode, Slice } from '@prismicio/types'
import { SliceType } from '../const'
import styles from './PageNavigation.module.css'
import { asHTML, asLink, HTMLFunctionSerializer } from '@prismicio/helpers'
import linkResolver from '../utils/linkResolver'

export type PageNavigationProps = Slice<
	SliceType.page_navigation,
	{
		previous?: RichTextField
		next?: RichTextField
	}
>

const Arrow = /*HTML*/ `
	<svg class="${styles.Arrow}" viewBox="0 0 81 8" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M0.646447 4.3534C0.451184 4.15814 0.451184 3.84156 0.646447 3.6463L3.82843 0.464318C4.02369 0.269056 4.34027 0.269056 4.53553 0.464318C4.7308 0.65958 4.7308 0.976162 4.53553 1.17142L2.20711 3.49985L81 3.49985V4.49985L2.20711 4.49985L4.53553 6.82828C4.7308 7.02354 4.7308 7.34012 4.53553 7.53539C4.34027 7.73065 4.02369 7.73065 3.82843 7.53539L0.646447 4.3534Z"
						/>
					</svg>
`

const serializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode,
	text?: string
): string | null | undefined => {
	if (type === 'hyperlink') {
		const {
			data,
			data: { link_type }
		} = node as RTLinkNode

		if (link_type === LinkType.Document) {
			return /* HTML */ `<a href="${asLink(data, linkResolver) || '/'}"
				>${Arrow}<span class="${styles.Label}">${text?.replace('\n', '<br/>')}</span></a
			>`
		} else {
			return null
		}
	}
	return null
}

export function PageNavigation(props: PageNavigationProps): string {
	const {
		primary: { previous, next }
	} = props

	return /* HTML */ `<div class="${styles.Main}">
		<div class="${styles.Inner}">
			${previous ? asHTML(previous, linkResolver, serializer) : ''}
			${next ? asHTML(next, linkResolver, serializer) : ''}
		</div>
	</div>`
}
