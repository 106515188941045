import {
	IUniform,
	LinearFilter,
	MeshBasicMaterial,
	PerspectiveCamera,
	Scene,
	ShaderMaterial,
	UniformsUtils,
	Vector2,
	WebGLRenderTarget
} from 'three'
import { FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass.js'
import { BloomImage } from './BloomImage'
import { App } from '../../app/App'
import { LuminosityHighPassShader } from 'three/examples/jsm/shaders/LuminosityHighPassShader.js'
import blurFragmentShader from './blur.frag'
import blurVertexShader from './blur.vert'
import { Stage } from './Stage'

const BACKGROUND_BLUR = 0.1
const DELAY = 0.1

export class Images {
	private readonly scene: Scene
	private readonly bloomScene: Scene
	private readonly camera: PerspectiveCamera
	private readonly fullScreenQuad: FullScreenQuad
	private readonly hBlur: WebGLRenderTarget
	private readonly vBlur: WebGLRenderTarget
	private readonly hBlurMaterial: ShaderMaterial
	private readonly vBlurMaterial: ShaderMaterial
	private readonly luminosityPass: WebGLRenderTarget
	private readonly materialHighPassFilter: ShaderMaterial
	private readonly basicMaterial: MeshBasicMaterial
	private readonly highPassUniforms: {
		luminosityThreshold: IUniform<number>
		smoothWidth: IUniform<number>
	}

	private images: BloomImage[] = []
	private pointer = new Vector2()
	private fov = 0

	private imageLoaded = 0
	private resolve: () => void = () => void 0

	constructor(private readonly stage: Stage, private readonly app: App) {
		this.onLoad = this.onLoad.bind(this)

		this.scene = new Scene()
		this.bloomScene = new Scene()
		this.camera = new PerspectiveCamera(36, 1, 0.1, 1000)
		this.camera.position.z = 500

		this.fullScreenQuad = new FullScreenQuad()

		this.luminosityPass = new WebGLRenderTarget(256, 256, {
			minFilter: LinearFilter,
			generateMipmaps: false
		})

		this.hBlur = new WebGLRenderTarget(256, 256, {
			minFilter: LinearFilter,
			generateMipmaps: false
		})

		this.vBlur = new WebGLRenderTarget(256, 256, {
			minFilter: LinearFilter,
			generateMipmaps: false
		})

		const highPassShader = LuminosityHighPassShader
		this.highPassUniforms = UniformsUtils.clone(highPassShader.uniforms)

		this.highPassUniforms['luminosityThreshold'].value = 0.3
		this.highPassUniforms['smoothWidth'].value = 0.01

		this.materialHighPassFilter = new ShaderMaterial({
			uniforms: this.highPassUniforms,
			vertexShader: highPassShader.vertexShader,
			fragmentShader: highPassShader.fragmentShader,
			defines: {}
		})

		this.hBlurMaterial = new ShaderMaterial({
			uniforms: {
				baseTexture: { value: null },
				delta: { value: new Vector2(BACKGROUND_BLUR, 0) },
				resolution: { value: new Vector2() }
			},
			fragmentShader: blurFragmentShader,
			vertexShader: blurVertexShader
		})

		this.vBlurMaterial = new ShaderMaterial({
			uniforms: {
				baseTexture: { value: null },
				delta: { value: new Vector2(0, BACKGROUND_BLUR) },
				resolution: { value: new Vector2() }
			},
			fragmentShader: blurFragmentShader,
			vertexShader: blurVertexShader
		})

		this.basicMaterial = new MeshBasicMaterial()
	}

	async hide(): Promise<void> {
		await Promise.all(this.images.map((image) => image.hide()))
	}

	setImageNodes(nodes: HTMLElement[]): void {
		this.imageLoaded = 0
		this.images = nodes.map((node) => new BloomImage(node, this.scene, this.stage, this.app, this.onLoad))
		this.resize()
	}

	async load(): Promise<void> {
		if (this.images.length === 0) return

		return new Promise((resolve) => (this.resolve = resolve))
	}

	onLoad(): void {
		this.imageLoaded++
		if (this.imageLoaded === this.images.length) {
			this.stage.renderer.compile(this.scene, this.camera)
			this.images.forEach((image) => this.renderBloom(image))
			this.resolve()
		}
	}

	renderBloom(node: BloomImage): void {
		const width = node.texture?.image.naturalWidth || 256
		const height = node.texture?.image.naturalHeight || 256

		this.luminosityPass.setSize(width, height)
		this.vBlur.setSize(width, height)
		this.hBlur.setSize(width, height)

		this.hBlurMaterial.uniforms.resolution.value.set(width, height)
		this.vBlurMaterial.uniforms.resolution.value.set(width, height)

		this.stage.renderer.clear()

		this.materialHighPassFilter.uniforms.tDiffuse.value = node.texture

		this.stage.renderer.setRenderTarget(this.luminosityPass)
		this.fullScreenQuad.material = this.materialHighPassFilter
		this.fullScreenQuad.render(this.stage.renderer)

		this.vBlurMaterial.uniforms.baseTexture.value = this.luminosityPass.texture

		this.stage.renderer.setRenderTarget(this.vBlur)
		this.fullScreenQuad.material = this.vBlurMaterial
		this.fullScreenQuad.render(this.stage.renderer)

		this.hBlurMaterial.uniforms.baseTexture.value = this.vBlur.texture

		this.stage.renderer.setRenderTarget(this.hBlur)
		this.fullScreenQuad.material = this.hBlurMaterial
		this.fullScreenQuad.render(this.stage.renderer)

		this.stage.renderer.setRenderTarget(node.renderTarget)
		this.basicMaterial.map = this.hBlur.texture
		this.fullScreenQuad.material = this.basicMaterial
		this.fullScreenQuad.render(this.stage.renderer)

		this.stage.renderer.setRenderTarget(null)
		node.setBloomMap()
	}

	resize() {
		this.fov = (this.camera.position.z * this.camera.getFilmHeight()) / this.camera.getFocalLength()
		this.images.forEach((node) => node.resize(this.fov))
	}

	dispose() {
		this.images.forEach((node) => node.dispose())
		this.camera.position.y = 0
	}

	scroll() {
		this.images.forEach((image) => image.scroll())
	}

	update(time: number) {
		const ratio = this.app.scrollY / this.stage.height
		this.camera.position.y = ratio * this.fov * -1

		this.pointer.x += (this.stage.pointer.x - this.pointer.x) * DELAY
		this.pointer.y += (this.stage.pointer.y - this.pointer.y) * DELAY

		this.images.forEach((image) => image.update(time, this.pointer))

		const inView = this.images.some((image) => image.inView)
		if (inView) {
			this.stage.renderer.render(this.scene, this.camera)
		}
	}
}
