export enum CustomType {
	pdf = 'pdf',
	anchor = 'anchor',
	header = 'header',
	footer = 'footer',
	accordion = 'accordion',
	slider = 'slider',
	page = 'page',
	form = 'form'
}

export enum SliceType {
	anchor = 'anchor',
	form = 'form',
	image = 'image',
	section = 'section',
	intro = 'intro',
	image_pair = 'image_pair',
	quote = 'quote',
	gallery = 'gallery',
	columns = 'columns',
	accordion = 'accordion',
	slider = 'slider',
	accordion_item = 'accordion_item',
	page_navigation = 'page_navigation',
	input_checkbox = 'checkbox',
	input_date = 'date',
	input_email = 'email',
	input_file = 'file',
	input_number = 'number',
	input_phone = 'phone',
	input_radio = 'radio',
	input_text = 'text',
	input_textarea = 'textarea',
	input_select = 'select',
	field_separator = 'separator',
	field_description = 'description'
}

export enum Lang {
	de = 'de-li',
	en = 'en-eu'
}

export enum ShortLang {
	'de-li' = 'de',
	'en-eu' = 'en'
}

export enum LangLabel {
	'de-li' = 'DE',
	'en-eu' = 'EN'
}

export enum EmailToFormID {
	'mario.winiker@kilokilo.ch' = 'xgejkzko',
	'info@schloss-freudenfels.ch' = 'xgejkzko'
}

export interface Controller {
	dispose?(): void

	resize?(): void

	show?(animate?: boolean): Promise<void>

	hide?(): Promise<void>

	update?(time: number): void

	load?(): Promise<void>

	scroll?(scrollY: number): void
}

export const TAG = {
	heading1: 'h1',
	heading2: 'h2',
	heading3: 'h3',
	heading4: 'h4',
	heading5: 'h5',
	heading6: 'h6',
	paragraph: 'p'
}

export const IMAGE_SIZES = [480, 960, 1440, 1920, 2560, 3800]
export type ColumnSpan = '6/6' | '5/6' | '4/6' | '3/6' | '2/6' | '1/6'
export type Display = 'inline' | 'grid'
export type Spacing = 'none' | 'small' | 'medium' | 'large'
