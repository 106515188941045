import { CustomType, Lang, ShortLang } from '../const'
import { FilledLinkToMediaField } from '@prismicio/types'

type Props = {
	uid?: string
	type: CustomType
	lang: Lang
	data?: { pdf?: FilledLinkToMediaField }
}

export const getLink = (props: Props): string => {
	const { uid, lang, type, data } = props

	let route = '/'

	if (!uid) return route

	if (type === CustomType.page) {
		route = `/${ShortLang[lang as Lang]}${uid === 'index' ? '/' : `/${uid}/`}`
	} else if (type === CustomType.anchor) {
		route = `#${uid}`
	} else if (type == CustomType.pdf) {
		route = data?.pdf?.url || '/404'
	}

	//static generation or existing route for preview
	if (
		typeof window === 'undefined' ||
		window.__ROUTES__.includes(route) ||
		type === CustomType.anchor ||
		type === CustomType.form
	) {
		return route
	}

	//route unpublished documents to generic page
	return `/unpublished/`
}
