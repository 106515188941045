import { FilledImageFieldImage, Slice } from '@prismicio/types'
import stageStyles from './stage/Stage.module.css'

import styles from './Image.module.css'
import { IMAGE_SIZES, SliceType } from '../const'
import { getSrcSet } from '../utils/getSrcSet'
import clsx from 'clsx'

export type ImageProps = Slice<
	SliceType.image,
	{
		image_landscape?: FilledImageFieldImage
		image_portrait?: FilledImageFieldImage
	}
>

export function Image(props: ImageProps): string {
	const portraitSrcSet = props.primary.image_portrait?.url
		? getSrcSet(props.primary.image_portrait.url, IMAGE_SIZES)
		: null

	const landscapeSrcSet = props.primary.image_landscape?.url
		? getSrcSet(props.primary.image_landscape.url, IMAGE_SIZES)
		: null

	const portraitWidth = props.primary.image_portrait?.dimensions?.width || 1
	const portraitHeight = props.primary.image_portrait?.dimensions?.height || 1
	const landscapeWidth = props.primary.image_landscape?.dimensions?.width || 1
	const landscapeHeight = props.primary.image_landscape?.dimensions?.height || 1

	const aspectPortrait = 1 / (portraitWidth / portraitHeight)
	const aspectLandscape = 1 / (landscapeWidth / landscapeHeight)

	const alt = props.primary.image_portrait?.alt || props.primary.image_landscape?.alt || ''

	return /* HTML */ ` <div
		class="${clsx(styles.Main, stageStyles.Image)}"
		style="--aspect-portrait: ${aspectPortrait}; --aspect-landscape: ${aspectLandscape}"
	>
		<picture>
			${landscapeSrcSet
				? `<source class="${styles.Source}" srcset="${landscapeSrcSet}" media="(orientation: landscape)" />`
				: ''}
			${portraitSrcSet
				? `<img
				class="${styles.Image}"
				srcset="${portraitSrcSet}"
				alt="${alt}"
				sizes="100vw"
				loading="eager"
				crossorigin="anonymous"
			/>`
				: ''}
		</picture>
	</div>`
}
