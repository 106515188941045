import { Easing, Group, Tween } from '@tweenjs/tween.js'

import { clamp } from '../utils/clamp'

export class Carousel {
	private readonly items: HTMLElement[]
	private readonly activePointers: PointerEvent[] = []
	private readonly group: Group = new Group()

	private raf = 0
	private index = 0
	private left = 0
	private startLeft = 0
	private pointerStart = 0
	private pointerOffset = 0
	private containerWidth = 0
	private startTime = 0
	private offsets: number[] = []

	private tween: Tween<{ left: number }> | null = null
	private dragging = false
	private needsDragUpdate = false
	private needsInitUpdate = false
	private ready = false
	private preventClick = false
	private cursor: 'default' | 'grabbing' = 'default'
	private actualCursor: 'default' | 'grabbing' = 'default'

	constructor(
		private readonly container: HTMLElement,
		private readonly activeClass: string = 'active',
		private readonly startIndex: number = 0
	) {
		this.update = this.update.bind(this)
		this.onResize = this.onResize.bind(this)
		this.onPointerStart = this.onPointerStart.bind(this)
		this.onPointerMove = this.onPointerMove.bind(this)
		this.onPointerEnd = this.onPointerEnd.bind(this)
		this.onPointerEnd = this.onPointerEnd.bind(this)
		this.onPointerEnd = this.onPointerEnd.bind(this)
		this.onClick = this.onClick.bind(this)

		this.items = Array.from(container.children) as HTMLElement[]

		this.raf = requestAnimationFrame(this.update)

		this.container.addEventListener('click', this.onClick)
		this.container.addEventListener('pointerdown', this.onPointerStart)
		document.addEventListener('pointermove', this.onPointerMove)
		document.addEventListener('pointerup', this.onPointerEnd)
		document.addEventListener('pointercancel', this.onPointerEnd)
		document.addEventListener('pointerleave', this.onPointerEnd)
		this.needsInitUpdate = true
	}

	onPointerStart(event: PointerEvent): void {
		this.activePointers.push(event)

		this.pointerStart = event.x
		this.pointerOffset = 0
		this.startTime = performance.now()
		this.tween?.stop()
	}

	onPointerMove(event: PointerEvent): void {
		if (this.activePointers.length === 1) {
			event.preventDefault()
			this.pointerOffset = this.pointerStart - event.x
			this.left = this.startLeft + this.pointerOffset
			this.updateIndex()
			this.dragging = true
			this.cursor = 'grabbing'
			this.needsDragUpdate = true
		}
	}

	onPointerEnd(event: PointerEvent): void {
		const index = this.activePointers.findIndex(({ pointerId }) => pointerId === event.pointerId)
		if (!this.activePointers[index]) return

		this.activePointers.splice(index, 1)

		if (this.dragging && this.activePointers.length === 0) {
			event.preventDefault()

			this.dragging = false
			this.cursor = 'default'
			this.preventClick = Math.abs(this.pointerOffset) > 3

			const duration = performance.now() - this.startTime
			const velocity = Math.abs(this.pointerOffset) / duration || 0

			const swipe = duration < 250 && velocity > 0.5 ? Math.sign(this.pointerOffset) : 0

			this.startLeft = this.left

			if (swipe !== 0) {
				this.index += swipe
				this.index = clamp(this.index, 0, this.items.length - 1)
			} else {
				this.updateIndex()
			}

			this.snapTo()
		}
	}

	onClick(event: MouseEvent): void {
		if (this.preventClick) {
			this.preventClick = false
			event.preventDefault()
			event.stopPropagation()
		}
	}

	updateItems() {
		this.items.forEach((item) => item.style.setProperty('transform', `translateX(${-this.left}px)`))
	}

	updateIndex() {
		this.index = 0
		const left = this.left
		for (let i = 0; i < this.offsets.length; i++) {
			const offset = this.offsets[i] + Math.abs(this.offsets[i] - this.offsets[i + 1] || 0) * 0.5
			this.index = i
			if (left <= offset) {
				break
			}
		}
	}

	snapTo() {
		const distance = Math.abs(this.left - this.offsets[this.index])
		const duration = Math.min((distance / this.containerWidth) * 1250, 1250)

		this.tween = new Tween({ left: this.left }, this.group)
			.to({ left: this.offsets[this.index] }, duration)
			.onUpdate(({ left }) => {
				this.left = left
				this.startLeft = left
				this.needsDragUpdate = true
			})
			.easing(Easing.Exponential.Out)
			.start()
	}

	onResize() {
		const { x, width } = this.container.getBoundingClientRect()
		this.containerWidth = width
		this.offsets = this.items.map((item) => item.getBoundingClientRect()?.x - x)
		this.ready = true
	}

	update(time: number) {
		this.raf = requestAnimationFrame(this.update)
		this.group.update(time)

		if (!this.ready) {
			return
		}

		if (this.needsInitUpdate) {
			this.needsInitUpdate = false

			// init on correct position if index !== 0
			if (this.startIndex !== 0) {
				this.index = this.startIndex
				this.left = this.offsets[this.index]
				this.startLeft = this.left
			}

			this.updateItems()
		}

		if (this.needsDragUpdate) {
			this.needsDragUpdate = false
			this.updateItems()
		}

		if (this.cursor !== this.actualCursor) {
			this.actualCursor = this.cursor
			this.actualCursor === 'default'
				? this.container.style.removeProperty('cursor')
				: this.container.style.setProperty('cursor', 'grabbing')
		}
	}

	dispose() {
		cancelAnimationFrame(this.raf)
		this.container.removeEventListener('click', this.onClick)
		this.container.removeEventListener('pointerdown', this.onPointerStart)
		document.removeEventListener('pointermove', this.onPointerMove)
		document.removeEventListener('pointerup', this.onPointerEnd)
		document.removeEventListener('pointercancel', this.onPointerEnd)
		document.removeEventListener('pointerleave', this.onPointerEnd)
		this.tween?.stop()
	}
}
