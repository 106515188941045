import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import fadeInStyles from './FadeIn.module.css'
import inputStyles from './Input.module.css'
import styles from './InputDate.module.css'

export type InputDateProps = Slice<
	SliceType.input_date,
	{
		name: string
		label: string
		default_value: string
		required: boolean
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
	}
>

export function InputDate(props: InputDateProps, preFilledDate?: string): string {
	const { primary } = props
	const {
		name,
		label,
		default_value,
		required = false,
		column_span_small = '6/6',
		column_span_large = '6/6'
	} = primary

	return /* HTML */ `
		<label
			class="${clsx(
				styles.Main,
				inputStyles.Main,
				fadeInStyles.Main,
				getColumnSpanClasses(column_span_small, column_span_large)
			)}"
			><span class="${inputStyles.Label}">${label}${required ? '*' : ''}</span>
			<input
				class="${clsx(styles.Input, inputStyles.Input)}"
				type="date"
				name="${name}"
				value="${preFilledDate ?? default_value}"
				${required ? 'required' : ''}
			/>
		</label>
	`
}
