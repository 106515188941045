import { Controller } from '../const'
import styles from './InputFile.module.css'

export class InputFileController implements Controller {
	private readonly input: HTMLInputElement
	private readonly placeholder: HTMLElement
	private readonly noFilesSelectedText: string

	constructor(private readonly node: HTMLElement) {
		this.updatePlaceholder = this.updatePlaceholder.bind(this)

		this.input = node.querySelector(`.${styles.Input}`) as HTMLInputElement
		this.placeholder = node.querySelector(`.${styles.Placeholder}`) as HTMLElement
		this.noFilesSelectedText = node.dataset.noFilesSelectedText || 'No files currently selected'

		this.input?.addEventListener('change', this.updatePlaceholder)
	}

	updatePlaceholder(): void {
		while (this.placeholder.firstChild) {
			this.placeholder.removeChild(this.placeholder.firstChild)
		}

		const currentFiles = this.input?.files ?? []
		if (currentFiles.length === 0) {
			this.placeholder.textContent = this.noFilesSelectedText
			this.placeholder.style.opacity = '0.2'
		} else {
			const file = currentFiles[0]
			this.placeholder.textContent = `${file.name} | ${this.returnFileSize(file.size)}`
			this.placeholder.style.opacity = '1'
		}
	}

	returnFileSize(number: number): string | undefined {
		if (number < 1024) {
			return `${number} bytes`
		} else if (number >= 1024 && number < 1048576) {
			return `${(number / 1024).toFixed(1)} KB`
		} else if (number >= 1048576) {
			return `${(number / 1048576).toFixed(1)} MB`
		}
	}

	dispose(): void {
		this.input?.removeEventListener('click', this.updatePlaceholder)
	}
}
