import { FilledImageFieldImage, Slice } from '@prismicio/types'
import { SliceType } from '../const'
import styles from './ImagePair.module.css'
import { ResponsiveImage } from './ResponsiveImage'
import clsx from 'clsx'
import stageStyles from './stage/Stage.module.css'

export type ImagePairProps = Slice<
	SliceType.image_pair,
	{
		layout?: 'left' | 'right'
		image_left?: FilledImageFieldImage
		image_right?: FilledImageFieldImage
	}
>

export function ImagePair(props: ImagePairProps, addTopPadding = false): string {
	const {
		primary: { layout, image_left, image_right }
	} = props

	return /* HTML */ `<div
		class="${clsx(styles.Main, layout == 'right' && styles.RightAligned, addTopPadding && styles.TopPadding)}"
	>
		${image_left
			? `<div class="${clsx(styles.Left, stageStyles.Image, stageStyles.Wobble)}">${ResponsiveImage({
					...image_left,
					loading: 'eager'
			  })}</div>`
			: ''}
		${image_right
			? `<div class="${clsx(styles.Right, stageStyles.Image, stageStyles.Wobble)}">${ResponsiveImage({
					...image_right,
					loading: 'eager'
			  })}</div>`
			: ''}
	</div>`
}
