import { RichTextField, Slice } from '@prismicio/types'
import { SliceType } from '../const'
import styles from './Columns.module.css'
import { Info } from './Info'

export type ColumnsProps = Slice<
	SliceType.columns,
	{},
	{
		eyebrow?: string
		title?: RichTextField
		text?: RichTextField
	}
>

export function Columns(props: ColumnsProps): string {
	const { items = [] } = props
	return /* HTML */ `<div class="${styles.Main}">
		<div class="${styles.Columns}">${items.map((item) => Info(item)).join('')}</div>
	</div>`
}
