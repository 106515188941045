import { CustomType, Lang } from '../const'
import { GroupField, PrismicDocumentWithoutUID, RichTextField } from '@prismicio/types'
import styles from './Footer.module.css'
import { asHTML } from '@prismicio/helpers'
import { Info } from './Info'
import linkResolver from '../utils/linkResolver'
import clsx from 'clsx'
import fadeInStyles from './FadeIn.module.css'
import { textSerializer } from '../utils/textSerializer'

export type FooterProps = PrismicDocumentWithoutUID<
	{
		navigation?: RichTextField
		columns?: GroupField<{
			eyebrow?: string
			title?: RichTextField
			text?: RichTextField
		}>
	},
	CustomType,
	Lang
>

export function Footer(props: FooterProps): string {
	const {
		data: { navigation, columns = [] }
	} = props
	return /* HTML */ `<div class="${styles.Main}">
		<div class="${styles.Columns}">${columns.map((column) => Info(column)).join('')}</div>
		${navigation
			? `<nav class="${clsx(styles.Navigation, fadeInStyles.Main)}">${asHTML(
					navigation,
					linkResolver,
					textSerializer
			  )}</nav>`
			: ''}
	</div>`
}
