import { App } from '../../app/App'
import {
	AmbientLight,
	Color,
	LinearFilter,
	MathUtils,
	Mesh,
	Object3D,
	PerspectiveCamera,
	PlaneGeometry,
	Scene,
	ShaderMaterial,
	TextureLoader,
	Vector2
} from 'three'
import leavesLeftMap from '../../assets/leaves-left.webp'
import leavesRightMap from '../../assets/leaves-right.webp'
import fg2Map from '../../assets/fg2.webp'
import fg1Map from '../../assets/fg1.webp'
import castleMap from '../../assets/castle.webp'
import bg1Map from '../../assets/bg1.webp'
import bg2Map from '../../assets/bg2.webp'
import skyMap from '../../assets/sky.jpg'
import { Easing, Group, Tween } from '@tweenjs/tween.js'
import { IntroPlane } from './IntroPlane'
import { Stage } from './Stage'

const MAPS = [leavesLeftMap, leavesRightMap, fg2Map, fg1Map, castleMap, bg1Map, bg2Map, skyMap]

const CAM_DELAY = 0.01
const CAM_Z = 16

export class Intro {
	private readonly scene: Scene = new Scene()
	private readonly camera: PerspectiveCamera = new PerspectiveCamera(36, 1, 0.25, 6000)
	private readonly dolly: Object3D = new Object3D()

	private readonly leavesLeft1: IntroPlane
	private readonly leavesLeft2: IntroPlane
	private readonly leavesRight1: IntroPlane
	private readonly leavesRight2: IntroPlane
	private readonly leavesLeft3: IntroPlane
	private readonly leavesRight3: IntroPlane
	private readonly castle: IntroPlane
	private readonly fg2: IntroPlane
	private readonly fg1: IntroPlane
	private readonly bg1: IntroPlane
	private readonly bg2: IntroPlane
	private readonly sky: IntroPlane
	private readonly pointer = new Vector2()
	private readonly pointerTarget = new Vector2()

	private readonly fade: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly vignette: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly group: Group = new Group()

	private top = 0
	private cameraY = 0
	private loaded = false
	private inView = false
	private node: HTMLElement | null = null
	private ratio = 0

	constructor(private readonly stage: Stage, private readonly app: App) {
		this.scene.add(this.dolly)
		this.dolly.add(this.camera)

		this.leavesLeft1 = new IntroPlane({
			parent: this.scene,
			// animate: true,
			z: 10,
			scale: 1.5,
			speed: 0.00002,
			float: true
			// hide: true
		})
		this.leavesLeft2 = new IntroPlane({
			parent: this.scene,
			// animate: true,
			z: 12,
			scale: 3,
			flip: true,
			y: -1,
			speed: 0.00002,
			float: true
			// hide: true
		})
		this.leavesLeft3 = new IntroPlane({
			parent: this.scene,
			// animate: true,
			z: 15,
			scale: 15,
			y: 0.25,
			speed: 0.00002,
			float: true
			// hide: true
		})
		this.leavesRight1 = new IntroPlane({
			parent: this.scene,
			// animate: true,
			z: 10.1,
			scale: 1.5,
			speed: 0.00002,
			float: true,
			size: 10
			// hide: true
		})
		this.leavesRight2 = new IntroPlane({
			parent: this.scene,
			// animate: true,
			z: 12.1,
			scale: 3,
			flip: true,
			y: -0.25,
			speed: 0.00002,
			float: true
			// hide: true
		})
		this.leavesRight3 = new IntroPlane({
			parent: this.scene,
			// animate: true,
			z: 15.1,
			scale: 15
			// hide: true
		})

		this.fg1 = new IntroPlane({ parent: this.scene, z: 5, animate: false, size: 5, strength: 0.005 })
		this.fg2 = new IntroPlane({ parent: this.scene, z: 8 })
		this.castle = new IntroPlane({ parent: this.scene, z: 0 })
		this.bg1 = new IntroPlane({ parent: this.scene, z: -40 })
		this.bg2 = new IntroPlane({ parent: this.scene, z: -400 })
		this.sky = new IntroPlane({ parent: this.scene, z: -5000 })

		this.scene.add(new AmbientLight(0xffffff))

		this.camera.position.z = CAM_Z

		const vignetteMaterial = new ShaderMaterial({
			transparent: true,
			depthTest: false,
			//language=GLSL
			vertexShader: `
				void main() {
					gl_Position = vec4(position, 1.0);
				}
			`,
			//language=GLSL
			fragmentShader: `
				uniform float alpha;
				uniform vec2 resolution;
				void main() {
					vec2 uv = gl_FragCoord.xy / resolution.xy;
					uv *=  1.0 - uv.yx;
					float vignette = uv.x * uv.y * 15.0;
					vignette = pow(vignette, 1.0);
					gl_FragColor = vec4(0.0,0.0,0.0,(1.0-vignette) * alpha);
				}
			`,
			uniforms: {
				resolution: { value: new Vector2(0, 0) },
				alpha: { value: 0.25 }
			}
		})

		this.vignette = new Mesh(new PlaneGeometry(2, 2), vignetteMaterial)
		this.vignette.renderOrder = 900
		this.vignette.frustumCulled = false
		this.scene.add(this.vignette)

		const fadeMaterial = new ShaderMaterial({
			transparent: true,
			depthTest: false,
			//language=GLSL
			vertexShader: `
			void main() {
				gl_Position = vec4(position, 1.0);
			}
			`,
			//language=GLSL
			fragmentShader: `
			uniform vec3 color;
			uniform float alpha;
			void main() {
				gl_FragColor = vec4(color, alpha);
			}
			`,
			uniforms: {
				color: { value: new Color(0x1f3f3e) },
				alpha: { value: 1 }
			}
		})

		this.fade = new Mesh(new PlaneGeometry(2, 2), fadeMaterial)
		this.fade.renderOrder = 1000
		this.fade.frustumCulled = false
		this.scene.add(this.fade)
	}

	async load(): Promise<void> {
		if (this.loaded) return

		const loader = new TextureLoader(this.stage.loadingManager)
		const textures = await Promise.all(MAPS.map((map) => loader.loadAsync(map)))
		textures.forEach((texture) => {
			texture.minFilter = LinearFilter
		})

		this.leavesLeft1.setTexture(textures[0])
		this.leavesLeft2.setTexture(textures[1])
		this.leavesLeft3.setTexture(textures[0])
		this.leavesRight1.setTexture(textures[1])
		this.leavesRight2.setTexture(textures[0])
		this.leavesRight3.setTexture(textures[1])
		this.fg2.setTexture(textures[2])
		this.fg1.setTexture(textures[3])
		this.castle.setTexture(textures[4])
		this.bg1.setTexture(textures[5])
		this.bg2.setTexture(textures[6])
		this.sky.setTexture(textures[7])

		this.stage.renderer.compile(this.scene, this.camera)
		this.loaded = true
	}

	async show(animate = false) {
		this.scroll()

		if (animate) {
			await this.animateIn()
		} else {
			new Tween({ alpha: 1 }, this.group)
				.to({ alpha: 0 }, 500)
				.onUpdate(({ alpha }) => (this.fade.material.uniforms.alpha.value = alpha))
				.start()
		}
	}

	async hide(): Promise<void> {
		if (!this.inView) return

		return new Promise((resolve) => {
			new Tween({ alpha: this.fade.material.uniforms.alpha.value }, this.group)
				.to({ alpha: 1 }, 250)
				.onUpdate(({ alpha }) => (this.fade.material.uniforms.alpha.value = alpha))
				.onComplete(() => resolve())
				.start()
		})
	}

	async animateIn() {
		return new Promise<void>((resolve) => {
			this.camera.position.z = 25

			this.leavesLeft1.position.x = 2
			this.leavesRight1.position.x = -2

			this.leavesLeft2.position.x = 2.5
			this.leavesRight2.position.x = -2.5

			this.leavesLeft3.position.x = 3
			this.leavesRight3.position.x = -3

			new Tween({ e: 0 }, this.group)
				.delay(250)
				.to({ e: 1 }, 6000)
				.easing(Easing.Sinusoidal.Out)
				.onUpdate(({ e }) => {
					this.leavesLeft1.position.x = MathUtils.lerp(1, 0, e)
					this.leavesLeft2.position.x = MathUtils.lerp(1.5, 0, e)
					this.leavesLeft3.position.x = MathUtils.lerp(2, 0, e)
					this.leavesRight1.position.x = MathUtils.lerp(-1, 0, e)
					this.leavesRight2.position.x = MathUtils.lerp(-1.5, 0, e)
					this.leavesRight3.position.x = MathUtils.lerp(-2, 0, e)
					this.vignette.material.uniforms.alpha.value = MathUtils.lerp(1.5, 0.25, e)
					this.camera.position.z = MathUtils.lerp(25, CAM_Z, e)
					this.pointer.set(0, 0)
				})
				.onComplete(() => resolve())
				.start()

			new Tween({ alpha: this.fade.material.uniforms.alpha.value }, this.group)
				.delay(500)
				.to({ alpha: 0 }, 2500)
				.onUpdate(({ alpha }) => (this.fade.material.uniforms.alpha.value = alpha))
				.start()
		})
	}

	setNode(node: HTMLElement) {
		this.node = node
		if (this.node) {
			this.resize()
		}
	}

	resize() {
		const { top } = this.node?.getBoundingClientRect() || { top: 0 }
		this.top = top + this.app.scrollY
		const aspect = this.stage.width / this.stage.height
		this.camera.aspect = aspect
		this.camera.updateProjectionMatrix()

		this.stage.renderer.getDrawingBufferSize(this.vignette.material.uniforms.resolution.value)

		const filmHeight = this.camera.getFilmHeight()
		const focalLength = this.camera.getFocalLength()

		this.leavesLeft1.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.leavesRight1.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.leavesLeft2.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.leavesRight2.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.leavesRight3.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.leavesLeft3.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.fg2.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.fg1.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.castle.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.bg1.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.bg2.resize(filmHeight, focalLength, CAM_Z, aspect)
		this.sky.resize(filmHeight, focalLength, CAM_Z, aspect)
	}

	scroll(): void {
		if (!this.node) {
			return
		}

		const scroll = this.app.scrollY - this.top
		this.ratio = scroll / this.stage.height
		this.cameraY = MathUtils.clamp(this.ratio * this.camera.aspect * -2, -1.25, 1.25)

		this.inView = this.ratio >= -1 && this.ratio <= 1
	}

	update(time: number) {
		if (!this.node) return
		if (!this.inView) return
		if (!this.loaded) return

		this.group.update(time)

		this.pointerTarget.x = MathUtils.clamp((this.stage.pointer.x / this.stage.width) * 2 - 1, -1, 1)
		this.pointerTarget.y = MathUtils.clamp(-(this.stage.pointer.y / this.stage.height) * 2 + 1, -1, 1)

		this.pointer.x += (this.pointerTarget.x - this.pointer.x) * CAM_DELAY
		this.pointer.y += (this.pointerTarget.y - this.pointer.y) * CAM_DELAY

		this.dolly.rotation.x = this.pointer.y * 0.0125
		this.dolly.rotation.y = this.pointer.x * -0.025

		this.camera.position.y += (this.cameraY - this.camera.position.y) * 0.05

		this.leavesLeft1.update(time)
		this.leavesLeft2.update(time)
		this.leavesLeft3.update(time)
		this.leavesRight1.update(time)
		this.leavesRight2.update(time)
		this.leavesRight3.update(time)
		this.fg2.update(time)
		this.fg1.update(time)
		this.castle.update(time)
		this.bg1.update(time)
		this.bg2.update(time)
		this.sky.update(time)

		this.stage.renderer.setViewport(0, this.stage.height * this.ratio, this.stage.width, this.stage.height)
		this.stage.renderer.setScissor(0, this.stage.height * this.ratio, this.stage.width, this.stage.height)
		this.stage.renderer.render(this.scene, this.camera)
		this.stage.renderer.setViewport(0, 0, this.stage.width, this.stage.height)
		this.stage.renderer.setScissor(0, 0, this.stage.width, this.stage.height)
	}

	dispose() {
		this.group.removeAll()
		this.node = null
		this.ratio = 0
		this.camera.position.y = 0
		this.pointer.set(0, 0)
		this.pointerTarget.set(0, 0)
		this.fade.material.uniforms.alpha.value = 1
		this.vignette.material.uniforms.alpha.value = 0.25
		this.leavesLeft1.position.x = 0
		this.leavesLeft2.position.x = 0
		this.leavesLeft3.position.x = 0
		this.leavesRight1.position.x = 0
		this.leavesRight2.position.x = 0
		this.leavesRight3.position.x = 0
		this.camera.position.z = CAM_Z
	}
}
