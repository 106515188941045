import { App } from '../app/App'
import { Controller } from '../const'
import styles from './Header.module.css'
import { Easing, Group, Tween } from '@tweenjs/tween.js'

export class HeaderController implements Controller {
	private readonly node: HTMLElement
	private readonly app: App
	private readonly button: HTMLElement
	private readonly close: HTMLElement
	private readonly menu: HTMLElement
	private readonly logo: HTMLElement
	private readonly logoLeft: HTMLElement
	private readonly logoRight: HTMLElement
	private readonly group: Group = new Group()
	private readonly items: HTMLElement[]
	private readonly mq: MediaQueryList
	private menuOpen = false

	constructor(node: HTMLElement, app: App) {
		this.closeMenu = this.closeMenu.bind(this)
		this.openMenu = this.openMenu.bind(this)

		this.app = app
		this.node = node

		this.button = node.querySelector(`.${styles.Button}`) as HTMLElement
		this.close = node.querySelector(`.${styles.Close}`) as HTMLElement
		this.menu = node.querySelector(`.${styles.Menu}`) as HTMLElement
		this.logo = node.querySelector(`.${styles.Logo}`) as HTMLElement
		this.logoLeft = this.logo.children[0] as HTMLElement
		this.logoRight = this.logo.children[1] as HTMLElement
		this.items = Array.from(node.querySelectorAll(`.${styles.Item}`)) as HTMLElement[]

		this.button.addEventListener('click', this.openMenu)
		this.close.addEventListener('click', this.closeMenu)

		this.items.forEach((item) => item.style.setProperty('opacity', '0'))

		this.mq = window.matchMedia('(min-width: 1024px)')
	}

	async openMenu(): Promise<void> {
		if (this.menuOpen) return
		this.menuOpen = true

		this.group.removeAll()
		this.items.forEach((item) => item.style.setProperty('opacity', '0'))

		new Tween({ opacity: 0 }, this.group)
			.to({ opacity: 1 }, 350)
			.easing(Easing.Exponential.Out)
			.onStart(() => {
				this.app.setBodyFixed()
				this.menu.style.setProperty('display', 'block')
				this.menu.style.setProperty('opacity', '0')
			})
			.onUpdate(({ opacity }) => this.menu.style.setProperty('opacity', `${opacity}`))
			.onComplete(() => this.close.style.removeProperty('opacity'))
			.start()

		new Tween({ scale: 0, opacity: 0 }, this.group)
			.to({ scale: 1, opacity: 1 }, 750)
			.delay(250)
			.easing(Easing.Exponential.Out)
			.onUpdate(({ scale, opacity }) => {
				this.close.style.setProperty('opacity', `${opacity}`)
				this.close.style.setProperty('transform', `scale(${scale}, ${scale})`)
			})
			.onComplete(() => {
				this.close.style.setProperty('opacity', '1')
				this.close.style.removeProperty('transform')
			})
			.start()

		this.items.forEach((item, index) =>
			new Tween({ x: -50, opacity: 0 }, this.group)
				.to({ x: 0, opacity: 1 }, 1250)
				.delay(250 + 100 * index)
				.easing(Easing.Exponential.Out)
				.onUpdate(({ x, opacity }) => {
					item.style.setProperty('opacity', `${opacity}`)
					item.style.setProperty('transform', `translateX(${x}%)`)
				})
				.onComplete(() => {
					item.style.setProperty('opacity', '1')
					item.style.removeProperty('transform')
				})
				.start()
		)
	}

	async closeMenu(): Promise<void> {
		if (!this.menuOpen) return
		this.menuOpen = false

		this.group.removeAll()

		return new Promise((resolve) => {
			new Tween({ opacity: 1 }, this.group)
				.to({ opacity: 0 }, 250)
				.onUpdate(({ opacity }) => this.menu.style.setProperty('opacity', `${opacity}`))
				.onComplete(() => {
					this.menu.style.removeProperty('opacity')
					this.menu.style.removeProperty('display')
					this.close.style.removeProperty('opacity')
					this.items.forEach((item) => item.style.setProperty('opacity', '0'))
					this.app.unsetBodyFixed()
					resolve()
				})
				.start()
		})
	}

	async show(showIntro?: boolean) {
		await Promise.all([this.showLogo(showIntro), this.showButton(), this.showItems(showIntro)])
	}

	async showItems(showIntro = false): Promise<void> {
		this.items.forEach((item) => item.style.setProperty('opacity', '0'))
		await Promise.all(
			this.items.map((item, index) => {
				return new Promise<void>((resolve) => {
					new Tween({ opacity: 0, y: -100 }, this.group)
						.to({ opacity: 1, y: 0 }, 750)
						.delay(index * 150 + (showIntro ? 2000 : 0))
						.easing(Easing.Exponential.Out)
						.onUpdate(({ opacity, y }) => {
							item.style.setProperty('transform', `translateY(${y}%)`)
							item.style.setProperty('opacity', `${opacity}`)
						})
						.onComplete(() => {
							item.style.removeProperty('transform')
							resolve()
						})
						.start()
				})
			})
		)
	}

	async showLogo(slow = false) {
		if (!this.mq.matches) return

		const prevLeft = this.logoLeft.getBoundingClientRect().left
		const prevRight = this.logoRight.getBoundingClientRect().left
		this.logo.classList.add(styles.Open)

		const left = this.logoLeft.getBoundingClientRect().left - prevLeft
		const right = this.logoRight.getBoundingClientRect().left - prevRight
		this.logo.classList.remove(styles.Open)

		return new Promise<void>((resolve) => {
			new Tween({ left: 0, right: 0 }, this.group)
				.to({ left, right, opacity: 1 }, slow ? 2500 : 1250)
				.easing(slow ? Easing.Sinusoidal.InOut : Easing.Cubic.InOut)
				.onUpdate(({ left, right }) => {
					this.logoLeft.style.setProperty('transform', `translateX(${left}px)`)
					this.logoRight.style.setProperty('transform', `translateX(${right}px)`)
				})
				.onComplete(() => {
					this.logo.classList.add(styles.Open)
					this.logoLeft.style.removeProperty('transform')
					this.logoRight.style.removeProperty('transform')
					resolve()
				})
				.start()
		})
	}

	async showButton(): Promise<void> {
		return new Promise<void>((resolve) => {
			new Tween({ opacity: 0, y: -100 }, this.group)
				.to({ opacity: 1, y: 0 }, 750)
				.delay(500)
				.easing(Easing.Exponential.Out)
				.onUpdate(({ opacity, y }) => {
					this.button.style.setProperty('transform', `translateY(${y}%)`)
					this.button.style.setProperty('opacity', `${opacity}`)
				})
				.onComplete(() => {
					this.button.style.setProperty('opacity', '1')
					this.button.style.removeProperty('transform')
					resolve()
				})
				.start()
		})
	}

	async hide(): Promise<void> {
		await this.closeMenu()
		await Promise.all([this.hideLogo(), this.hideButton(), this.hideItems()])
	}

	async hideItems(): Promise<void> {
		await Promise.all(
			this.items.map((item, index) => {
				return new Promise<void>((resolve) => {
					new Tween({ opacity: 1 }, this.group)
						.to({ opacity: 0 }, 250)
						.delay(index * 50)
						.onUpdate(({ opacity }) => item.style.setProperty('opacity', `${opacity}`))
						.onComplete(() => resolve())
						.start()
				})
			})
		)
	}

	async hideLogo(): Promise<void> {
		if (!this.mq.matches) return

		const prevLeft = this.logoLeft.getBoundingClientRect().left
		const prevRight = this.logoRight.getBoundingClientRect().left
		this.logo.classList.remove(styles.Open)

		const left = prevLeft - this.logoLeft.getBoundingClientRect().left
		const right = prevRight - this.logoRight.getBoundingClientRect().left

		return new Promise<void>((resolve) => {
			new Tween({ left, right }, this.group)
				.to({ left: 0, right: 0 }, 750)
				.easing(Easing.Quartic.InOut)
				.onUpdate(({ left, right }) => {
					this.logoLeft.style.setProperty('transform', `translateX(${left}px)`)
					this.logoRight.style.setProperty('transform', `translateX(${right}px)`)
				})
				.onComplete(() => {
					this.logoLeft.style.removeProperty('transform')
					this.logoRight.style.removeProperty('transform')
					resolve()
				})
				.start()
		})
	}

	async hideButton(): Promise<void> {
		return new Promise((resolve) => {
			new Tween({ opacity: 1 }, this.group)
				.to({ opacity: 0 }, 250)
				.delay(150)
				.onUpdate(({ opacity }) => this.button.style.setProperty('opacity', `${opacity}`))
				.onComplete(() => resolve())
				.start()
		})
	}

	update(time: number) {
		this.group.update(time)
	}

	dispose(): void {
		this.group.removeAll()
		this.button.removeEventListener('click', this.openMenu)
		this.close.removeEventListener('click', this.closeMenu)
	}
}
