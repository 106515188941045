import { Controller } from '../const'
import styles from './ResponsiveImage.module.css'

export class ResponsiveImageController implements Controller {
	private readonly image: HTMLImageElement | null
	private ready = false

	constructor(node: HTMLElement) {
		this.onLoad = this.onLoad.bind(this)
		this.image = node.querySelector(`.${styles.Image}`)
		this.image?.addEventListener('load', this.onLoad)
		this.resize()
	}

	resize(): void {
		const { width } = this.image?.getBoundingClientRect() || { width: 0 }
		if (width > 0) {
			this.image?.setAttribute('size', `${width}px`)
			if (!this.ready) {
				this.ready = true
				this.image?.setAttribute('srcset', this.image?.dataset?.srcset || '')
				this.image?.removeAttribute('data-srcset')
			}
		}
	}

	onLoad() {
		this.image?.removeEventListener('load', this.onLoad)
		this.image?.classList.add(styles.IsLoaded)
	}

	dispose() {
		this.image?.removeEventListener('load', this.onLoad)
	}
}
