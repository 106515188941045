import '../index.css'

import * as prismic from '@prismicio/client'
import { Group, Tween, update } from '@tweenjs/tween.js'
import cookie from 'js-cookie'

import { repo_name } from '../../config.json'
import bodyStyles from '../Body.module.css'
import accordionStyles from '../components/Accordion.module.css'
import { AccordionController } from '../components/AccordionController'
import anchorStyles from '../components/Anchor.module.css'
import { AnchorController } from '../components/AnchorController'
import fadeInStyles from '../components/FadeIn.module.css'
import { FadeInController } from '../components/FadeInController'
import formStyles from '../components/Form.module.css'
import { FormController } from '../components/FormController'
import headerStyles from '../components/Header.module.css'
import { HeaderController } from '../components/HeaderController'
import imageStyles from '../components/Image.module.css'
import { ImageController } from '../components/ImageController'
import inputFileStyles from '../components/InputFile.module.css'
import { InputFileController } from '../components/InputFileController'
import introStyles from '../components/intro/Intro.module.css'
import responsiveImageStyles from '../components/ResponsiveImage.module.css'
import { ResponsiveImageController } from '../components/ResponsiveImageController'
import sliderStyles from '../components/Slider.module.css'
import { SliderController } from '../components/SliderController'
import { Stage } from '../components/stage/Stage'
import stageStyles from '../components/stage/Stage.module.css'
import { Controller } from '../const'
import pageStyles from '../pages/Page.module.css'
import { delay } from '../utils/delay'
import { getScrollbarWidth } from '../utils/getScrollbarWidth'
import { EventEmitter } from './EventEmitter'
import { getPreview } from './Preview'
import { Router } from './Router'

export class App extends EventEmitter {
	private readonly parser: DOMParser = new DOMParser()
	private readonly previewRef: string | undefined
	private readonly hasPreview: boolean
	private readonly group: Group = new Group()
	private readonly stage: Stage
	private readonly observer: ResizeObserver

	private main: HTMLElement
	private inner: HTMLElement
	private controllers: Controller[] = []
	private isBodyFixed = true
	private needsScrollUpdate = false

	public readonly intersectionObserver: IntersectionObserver
	public readonly router: Router

	private pointerX = 0
	private pointerY = 0
	private bodyWidth = 0
	private bodyHeight = 0

	public windowWidth = 0
	public windowHeight = 0
	public scrollY = 0

	constructor() {
		super()
		this.update = this.update.bind(this)

		//remove hash on load
		history.replaceState('', document.title, `${window.location.pathname}${window.location.search}`)

		this.main = document.querySelector(`.${pageStyles.Main}`) as HTMLElement
		this.inner = document.querySelector(`.${pageStyles.Inner}`) as HTMLElement
		this.isBodyFixed = document.body.classList.contains(bodyStyles.IsFixed)

		const scrollbarWidth = getScrollbarWidth()
		document.body.style.setProperty('--scrollbar', `${scrollbarWidth}px`)

		this.router = new Router()
		this.router.on('change', this.onNavigate.bind(this))
		this.intersectionObserver = new IntersectionObserver((entries) => this.emit('intersect', entries))

		const container = document.querySelector(`.${stageStyles.Container}`) as HTMLElement
		const loader = document.querySelector(`.${stageStyles.Loader}`) as HTMLElement
		this.stage = new Stage(container, loader, this)

		const previewCookie = cookie.get(prismic.cookie.preview)
		this.previewRef = previewCookie
		this.hasPreview = previewCookie ? !!JSON.parse(previewCookie)[`${repo_name}.prismic.io`] : false

		this.observer = new ResizeObserver(this.onResize.bind(this))

		document.fonts.ready.then(() => this.init())
	}

	async init(): Promise<void> {
		if (this.hasPreview) {
			const html = await this.loadPage(window.location.pathname)
			if (html) this.updatePage(html)
		}

		requestAnimationFrame(this.update)
		document.addEventListener('click', this.onDocumentClick.bind(this))
		document.addEventListener('pointermove', this.onPointerMove.bind(this))
		window.addEventListener('scroll', this.onScroll.bind(this))

		this.initControllers()

		const animate = this.main?.dataset?.animate === 'true'

		await Promise.all(
			[
				...this.controllers.map((controller) => controller.load?.()),
				this.stage.load(animate),
				animate ? delay(1000) : null
			].filter(Boolean)
		)

		this.observer.observe(document.body)

		if (animate) {
			this.inner?.style.setProperty('opacity', '1')
		}

		this.controllers.forEach((controller) => controller.show?.(animate))
		this.stage.show(animate)

		await delay(500)
	}

	onPointerMove(event: PointerEvent): void {
		this.pointerX = event.x
		this.pointerY = event.y
	}

	onDocumentClick(event: MouseEvent): void {
		let target: HTMLElement = event.target as HTMLElement

		while (target && target.parentNode) {
			if (target.tagName === 'A') {
				const { origin, pathname, hash } = new URL((target as HTMLAnchorElement).href)
				if (origin === window.location.origin && !(hash && pathname === window.location.pathname)) {
					event.preventDefault()
					const href = (target as HTMLAnchorElement).getAttribute('href')
					if (href) {
						this.router.push(href)
					}
				}
				break
			}
			target = target.parentNode as HTMLElement
		}
	}

	async onNavigate(route: string): Promise<void> {
		this.setBodyFixed()

		const [html] = await Promise.all([
			this.loadPage(route),
			this.stage.hide(),
			this.fadeOut(),
			...this.controllers.map((controller) => controller.hide?.()).filter(Boolean)
		])

		if (!html) {
			return
		}

		this.stage.dispose()
		this.controllers.forEach((controller) => controller.dispose?.())

		this.updatePage(html)
		const animate = this.main?.dataset?.animate === 'true'

		this.initControllers()

		await Promise.all([...this.controllers.map((controller) => controller.load?.()), this.stage.load()])

		this.controllers.forEach((controller) => controller.show?.(animate))
		this.stage.show(animate)

		this.unsetBodyFixed()
		this.onResize()
	}

	async loadPage(route?: string): Promise<string> {
		if (this.hasPreview && this.previewRef) {
			return await getPreview(this.previewRef, route)
		} else {
			const response = await fetch(`${route}index.html`)
			return await response.text()
		}
	}

	updatePage(html: string): void {
		const doc = this.parser.parseFromString(html, 'text/html')
		const mainNode = doc.querySelector(`.${pageStyles.Main}`) as HTMLElement

		document.title = doc.title
		document.documentElement.lang = doc.documentElement.lang

		mainNode.dataset.background === 'rose'
			? document.body.classList.add(bodyStyles.Rose)
			: document.body.classList.remove(bodyStyles.Rose)

		window.scrollTo(0, 0)
		this.scrollY = 0
		this.main?.replaceWith(mainNode)
		this.main = document.querySelector(`.${pageStyles.Main}`) as HTMLElement
		this.inner = this.main.querySelector(`.${pageStyles.Inner}`) as HTMLElement
	}

	initControllers(): void {
		this.controllers = [
			...this.getNodes(fadeInStyles.Main).map((node) => new FadeInController(node, this)),
			...this.getNodes(imageStyles.Main).map((node) => new ImageController(node)),
			...this.getNodes(responsiveImageStyles.Main).map((node) => new ResponsiveImageController(node)),
			...this.getNodes(headerStyles.Main).map((node) => new HeaderController(node, this)),
			...this.getNodes(accordionStyles.Main).map((node) => new AccordionController(node)),
			...this.getNodes(sliderStyles.Main).map((node) => new SliderController(node)),
			...this.getNodes(anchorStyles.Main).map((node) => new AnchorController(node, this)),
			...this.getNodes(formStyles.Main).map((node) => new FormController(node, this)),
			...this.getNodes(inputFileStyles.Main).map((node) => new InputFileController(node))
		]

		const introNode = this.main?.querySelector(`.${introStyles.Main}`) as HTMLElement
		const imageNodes = Array.from(this.main?.querySelectorAll(`.${stageStyles.Image}`) as NodeListOf<HTMLElement>)

		if (introNode) {
			this.stage.setIntroNode(introNode)
		}
		this.stage.setImageNodes(imageNodes)
	}

	getNodes(className: string): HTMLElement[] {
		return Array.from(this.main?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
	}

	setBodyFixed(): void {
		if (this.isBodyFixed) return
		this.isBodyFixed = true
		this.scrollY = window.scrollY

		this.stage.setFixed()
		this.inner?.style.setProperty('transform', `translateY(${this.scrollY * -1}px)`)
		document.body.classList.add(bodyStyles.IsFixed)
	}

	unsetBodyFixed(): void {
		if (!this.isBodyFixed) return
		this.isBodyFixed = false

		this.stage.unsetFixed()
		document.body.classList.remove(bodyStyles.IsFixed)
		this.inner?.style.removeProperty('transform')
		window.scrollTo(0, this.scrollY)
	}

	async fadeOut(): Promise<void> {
		return new Promise<void>((resolve) =>
			new Tween({ opacity: 1 }, this.group)
				.to({ opacity: 0 }, 250)
				.onUpdate(({ opacity }) => this.inner?.style.setProperty('opacity', `${opacity}`))
				.onComplete(() => {
					this.inner?.style.setProperty('opacity', '0')
					resolve()
				})
				.start()
		)
	}

	onResize(): void {
		const { width, height } = this.main.getBoundingClientRect()

		//prevent resize events on scroll
		if (this.bodyWidth !== width || this.bodyHeight !== height) {
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
			this.bodyWidth = width
			this.bodyHeight = height
			this.stage.resize()
			this.controllers.forEach((controller) => controller.resize?.())
		}
	}

	onScroll(): void {
		this.needsScrollUpdate = true
	}

	update(time: number): void {
		requestAnimationFrame(this.update)
		update(time)

		this.group.update(time)

		if (this.needsScrollUpdate && !this.isBodyFixed) {
			this.needsScrollUpdate = false
			this.scrollY = window.scrollY
			this.stage.scroll()
			this.controllers.forEach((controller) => controller.scroll?.(window.scrollY))
		}

		this.stage.update(time)
		this.controllers.forEach((controller) => controller.update?.(time))
	}
}
