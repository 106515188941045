import { Controller } from '../const'
import { App } from '../app/App'

export class AnchorController implements Controller {
	private readonly id: string | undefined

	constructor(
		private readonly node: HTMLElement,
		private readonly app: App
	) {
		this.onIntersect = this.onIntersect.bind(this)
		this.onHashChange = this.onHashChange.bind(this)
		this.id = node.dataset.id
		window.addEventListener('hashchange', this.onHashChange)
		this.app.on('intersect', this.onIntersect)
		this.app.intersectionObserver.observe(this.node)
	}

	onIntersect(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.target === this.node) {
				if (!entry.isIntersecting) {
					const hash = window.location.hash.replace('#', '')
					if (this.id === hash) {
						const { origin, pathname, search } = window.location
						window.history.pushState({}, document.title, `${origin}${pathname}${search}`)
					}
				}
			}
		})
	}

	onHashChange() {
		const hash = window.location.hash.replace('#', '')
		if (this.id === hash) this.node.scrollIntoView({ behavior: 'smooth' })
	}

	dispose() {
		window.removeEventListener('hashchange', this.onHashChange)
		this.app.removeListener('intersect', this.onIntersect)
		this.app.intersectionObserver.unobserve(this.node)
	}
}
