import { AlternateLanguage, GroupField, PrismicDocument, RichTextField } from '@prismicio/types'

import { CustomType, Lang, LangLabel, ShortLang } from '../const'

import styles from './Header.module.css'
import { asHTML } from '@prismicio/helpers'
import linkResolver from '../utils/linkResolver'
import { getLink } from '../utils/getLink'
import clsx from 'clsx'

export type HeaderProps = PrismicDocument<
	{
		navigation?: GroupField<{
			link?: RichTextField
		}>
	},
	CustomType,
	Lang
> & {
	alternate?: AlternateLanguage<CustomType, Lang>
	route: string
}

const LogoTop = `<svg viewBox="0 0 217 47" xmlns="http://www.w3.org/2000/svg">
	<path d="M0 18.5289H9.38798V18.0348H4.94104L4.44694 17.5407V9.73385H8.15272L9.4868 13.5879H9.88209V5.43515H9.4868L8.15272 9.23975H4.44694V1.48231L4.94104 0.988209H11.1173L13.7114 7.75744L14.082 7.65862L13.3408 0.494105H0V0.988209H1.48231L1.97642 1.48231V17.5407L1.48231 18.0348H0V18.5289Z"/>
	<path d="M18.1525 18.5289H36.1873V18.0348H34.2109L27.7382 9.53621C30.8016 9.14093 32.8521 7.33745 32.8521 5.06457C32.8521 2.32229 30.1346 0.494105 26.0582 0.494105H18.1525V0.988209H20.129L20.6231 1.48231V17.5407L20.129 18.0348H18.1525V18.5289ZM23.0936 9.14093V1.48231L23.5877 0.988209H26.0582C28.504 0.988209 30.1346 2.61875 30.1346 5.06457C30.1346 7.51039 28.504 9.14093 26.0582 9.14093H23.0936ZM23.5877 18.0348L23.0936 17.5407V9.63503H24.8229L30.9004 18.0348H23.5877Z"/>
	<path d="M39.8999 18.5289H53.9819L54.723 12.3526L54.3524 12.2538L52.0054 18.0348H44.8409L44.3468 17.5407V9.51151H48.0526L49.4114 13.8349H49.782V4.69399H49.4114L48.0526 9.0174H44.3468V1.48231L44.8409 0.988209H51.2643L53.6113 6.76923L53.9819 6.67041L53.2407 0.494105H39.8999V0.988209H41.3822L41.8763 1.48231V17.5407L41.3822 18.0348H39.8999V18.5289Z"/>
	<path d="M67.9137 19.023C72.2618 19.023 74.3864 16.7007 74.3864 12.3526V1.48231L74.8805 0.988209H77.0793V0.494105H58.5504V0.988209H60.5268L61.0209 1.48231V12.3526C61.0209 16.7748 63.3432 19.023 67.9137 19.023ZM68.4325 18.4301C65.1467 18.4301 63.4914 15.8855 63.4914 12.3526V1.48231L63.9855 0.988209H73.2747L73.7688 1.48231V12.3526C73.7688 16.5772 71.6689 18.4301 68.4325 18.4301Z"/>
	<path d="M81.3921 18.5289H90.0389C96.0176 18.5289 99.674 15.0949 99.674 9.51151C99.674 3.92813 96.0176 0.494105 90.0389 0.494105H81.3921V0.988209H83.3685L83.8626 1.48231V17.5407L83.3685 18.0348H81.3921V18.5289ZM86.8272 18.0348L86.3331 17.5407V1.48231L86.8272 0.988209H90.0389C94.3376 0.988209 96.7093 4.69399 96.7093 9.51151C96.7093 14.329 94.3376 18.0348 90.0389 18.0348H86.8272Z"/>
	<path d="M103.984 18.5289H118.066L118.807 12.3526L118.436 12.2538L116.089 18.0348H108.925L108.431 17.5407V9.51151H112.137L113.495 13.8349H113.866V4.69399H113.495L112.137 9.0174H108.431V1.48231L108.925 0.988209H115.348L117.695 6.76923L118.066 6.67041L117.325 0.494105H103.984V0.988209H105.466L105.96 1.48231V17.5407L105.466 18.0348H103.984V18.5289Z"/>
	<path d="M123.005 18.5289H131.405V18.0348H127.205L126.711 17.5407V3.95283H126.958L138.322 18.5289H139.187V1.48231L139.681 0.988209H142.275V0.494105H133.875V0.988209H138.075L138.569 1.48231V14.082H138.322L127.699 0.494105H123.005V0.988209H125.599L126.093 1.48231V17.5407L125.599 18.0348H123.005V18.5289Z"/>
	<path d="M146.851 18.5289H156.239V18.0348H151.792L151.298 17.5407V9.73385H155.004L156.338 13.5879H156.733V5.43515H156.338L155.004 9.23975H151.298V1.48231L151.792 0.988209H157.969L160.563 7.75744L160.933 7.65862L160.192 0.494105H146.851V0.988209H148.334L148.828 1.48231V17.5407L148.334 18.0348H146.851V18.5289Z"/>
	<path d="M165.004 18.5289H179.086L179.827 12.3526L179.456 12.2538L177.109 18.0348H169.945L169.451 17.5407V9.51151H173.157L174.515 13.8349H174.886V4.69399H174.515L173.157 9.0174H169.451V1.48231L169.945 0.988209H176.368L178.715 6.76923L179.086 6.67041L178.345 0.494105H165.004V0.988209H166.486L166.98 1.48231V17.5407L166.486 18.0348H165.004V18.5289Z"/>
	<path d="M184.025 18.5289H198.107L198.848 13.0938L198.453 12.9949L195.883 18.0348H188.966L188.472 17.5407V1.48231L188.966 0.988209H190.448V0.494105H184.025V0.988209H185.507L186.001 1.48231V17.5407L185.507 18.0348H184.025V18.5289Z"/>
	<path d="M209.679 19.023C213.977 19.023 216.497 16.9478 216.497 13.8349C216.497 11.5373 215.237 10.1785 212.223 8.94329L209.308 7.75744C206.739 6.69511 205.652 5.58338 205.652 4.00225C205.652 2.05053 206.961 0.494105 209.012 0.494105C211.976 0.494105 213.903 2.56934 214.793 6.74452L215.287 6.67041L214.545 0.494105H214.051C211.754 0.494105 210.716 0 208.987 0C205.602 0 203.428 1.8776 203.428 4.7187C203.428 7.06569 204.639 8.35036 207.504 9.56092L210.617 10.8703C212.717 11.7597 214.051 12.7726 214.051 14.502C214.051 16.7995 212.198 18.5042 209.679 18.5042C206.096 18.5042 204.194 16.4043 203.181 11.0679L202.687 11.1173L203.428 18.5289H203.922C206.269 18.5289 207.579 19.023 209.679 19.023Z"/>
</svg> `

const LogoBottom = `<svg viewBox="0 0 217 47" xmlns="http://www.w3.org/2000/svg">
	<path d="M48.7102 36.1163V27.9639H49.9455V36.1163H50.6691L56.7219 30.0635L56.8966 30.2382L50.1534 36.9814H59.0862V37.2285H50.1204L56.8968 44.0049L56.7221 44.1796L50.6352 38.0927H49.9455V46.2457H48.7102V38.0927H48.0538L41.967 44.1796L41.7923 44.0049L48.5687 37.2285H39.5691V36.9814H48.5359L41.7927 30.2382L41.9674 30.0635L48.0201 36.1163H48.7102Z"/>
	<path d="M68.2273 36.1163V27.9639H69.4626V36.1163H70.1865L76.2392 30.0635L76.4139 30.2382L69.6707 36.9814L87.5703 36.9814L80.8271 30.2382L81.0018 30.0635L87.0545 36.1163H87.7442V27.9639H88.9794V36.1163H89.7036L95.7563 30.0635L95.931 30.2382L89.1878 36.9814L107.087 36.9814L100.344 30.2382L100.519 30.0635L106.571 36.1163H107.261V27.9639H108.497V36.1163H109.221L115.273 30.0635L115.448 30.2382L108.705 36.9814H117.637V37.2285H108.672L115.448 44.0049L115.273 44.1796L109.186 38.0927H108.497V46.2457H107.261V38.0927H106.605L100.518 44.1796L100.344 44.0049L107.12 37.2285L89.1548 37.2285L95.9312 44.0049L95.7565 44.1796L89.6696 38.0927H88.9794V46.2457H87.7442V38.0927H87.0883L81.0014 44.1796L80.8267 44.0049L87.6031 37.2285L69.6372 37.2285L76.4136 44.0049L76.2389 44.1796L70.152 38.0927H69.4626V46.2457H68.2273V38.0927H67.5712L61.4843 44.1796L61.3096 44.0049L68.086 37.2285L59.0862 37.2285V36.9814L68.0527 36.9814L61.3095 30.2382L61.4842 30.0635L67.537 36.1163H68.2273Z"/>
	<path d="M131.488 34.8813C131.488 36.4378 130.228 37.4754 128.079 37.4754C127.568 37.4754 127.151 37.417 126.723 37.357C126.27 37.2936 125.804 37.2283 125.201 37.2283H124.954L124.583 33.5225L124.83 33.4978C125.337 36.166 126.288 37.216 128.079 37.216C129.339 37.216 130.265 36.3636 130.265 35.2148C130.265 34.3502 129.598 33.8437 128.548 33.399L126.992 32.7443C125.559 32.139 124.954 31.4967 124.954 30.3232C124.954 28.9027 126.041 27.9639 127.733 27.9639C128.135 27.9639 128.462 28.0172 128.812 28.0743C129.216 28.1401 129.65 28.2109 130.265 28.2109H130.512L130.883 31.2991L130.636 31.3361C130.191 29.2485 129.228 28.2109 127.745 28.2109C126.72 28.2109 126.065 28.9891 126.065 29.965C126.065 30.7556 126.609 31.3114 127.894 31.8426L129.351 32.4355C130.858 33.0531 131.488 33.7325 131.488 34.8813Z"/>
	<path d="M145.758 33.7696C145.313 36.2648 144.201 37.4754 142.089 37.4754C139.248 37.4754 137.481 35.6472 137.481 32.7196C137.481 29.8168 139.211 27.9639 141.99 27.9639C142.466 27.9639 142.825 28.0185 143.206 28.0764C143.634 28.1416 144.089 28.2109 144.769 28.2109H145.016L145.511 31.7932L145.251 31.8302C144.831 29.4585 143.855 28.2109 142.077 28.2109C140.162 28.2109 138.964 30.175 138.964 32.7196C138.964 35.3013 140.298 37.0554 142.287 37.0554C144.041 37.0554 145.004 36.0548 145.498 33.7325L145.758 33.7696Z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M151.567 37.2283H161.325V36.9813H160.337L160.09 36.7342V28.705L160.337 28.458H161.325V28.2109H151.567V28.458H152.555L152.802 28.705V36.7342L152.555 36.9813H151.567V37.2283ZM154.037 32.4108V28.705L154.284 28.458H158.608L158.855 28.705V32.4108H154.037ZM154.284 36.9813L154.037 36.7342V32.6579H158.855V36.7342L158.608 36.9813H154.284Z"/>
	<path d="M174.109 37.2283H167.068V36.9813H167.809L168.056 36.7342V28.705L167.809 28.458H167.068V28.2109H170.279V28.458H169.538L169.291 28.705V36.7342L169.538 36.9813H172.997L174.282 34.4613L174.479 34.5107L174.109 37.2283Z"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M184.675 37.4754C187.529 37.4754 189.431 35.5731 189.431 32.7196C189.431 29.8662 187.529 27.9639 184.675 27.9639C181.822 27.9639 179.919 29.8662 179.919 32.7196C179.919 35.5731 181.822 37.4754 184.675 37.4754ZM184.675 37.2283C182.711 37.2283 181.402 35.4248 181.402 32.7196C181.402 30.0144 182.711 28.2109 184.675 28.2109C186.639 28.2109 187.949 30.0144 187.949 32.7196C187.949 35.4248 186.639 37.2283 184.675 37.2283Z"/>
	<path d="M202.323 34.8813C202.323 36.4378 201.063 37.4754 198.913 37.4754C198.403 37.4754 197.986 37.417 197.557 37.357C197.104 37.2936 196.638 37.2283 196.035 37.2283H195.788L195.418 33.5225L195.665 33.4978C196.171 36.166 197.122 37.216 198.913 37.216C200.173 37.216 201.1 36.3636 201.1 35.2148C201.1 34.3502 200.433 33.8437 199.383 33.399L197.826 32.7443C196.393 32.139 195.788 31.4967 195.788 30.3232C195.788 28.9027 196.875 27.9639 198.567 27.9639C198.969 27.9639 199.296 28.0172 199.646 28.0743C200.05 28.1401 200.485 28.2109 201.1 28.2109H201.347L201.717 31.2991L201.47 31.3361C201.026 29.2485 200.062 28.2109 198.58 28.2109C197.555 28.2109 196.9 28.9891 196.9 29.965C196.9 30.7556 197.443 31.3114 198.728 31.8426L200.186 32.4355C201.693 33.0531 202.323 33.7325 202.323 34.8813Z"/>
	<path d="M211.688 37.4754C213.837 37.4754 215.097 36.4378 215.097 34.8813C215.097 33.7325 214.467 33.0531 212.96 32.4355L211.503 31.8426C210.218 31.3114 209.675 30.7556 209.675 29.965C209.675 28.9891 210.329 28.2109 211.355 28.2109C212.837 28.2109 213.8 29.2485 214.245 31.3361L214.492 31.2991L214.122 28.2109H213.875C213.259 28.2109 212.825 28.1401 212.421 28.0743C212.071 28.0172 211.744 27.9639 211.342 27.9639C209.65 27.9639 208.563 28.9027 208.563 30.3232C208.563 31.4967 209.168 32.139 210.601 32.7443L212.158 33.399C213.207 33.8437 213.875 34.3502 213.875 35.2148C213.875 36.3636 212.948 37.216 211.688 37.216C209.897 37.216 208.946 36.166 208.439 33.4978L208.192 33.5225L208.563 37.2283H208.81C209.413 37.2283 209.879 37.2936 210.332 37.357C210.761 37.417 211.178 37.4754 211.688 37.4754Z"/>
</svg>
`

export function Header(props: HeaderProps): string {
	const { lang, data, alternate, route } = props
	return /* HTML */ `
		<header class="${styles.Main}">
			<div class="${styles.Bar}">
				<a class="${styles.Logo}" href="/${ShortLang[lang]}/"> ${LogoTop} ${LogoBottom} </a>
				<button class="${styles.Button}">Navigation</button>
			</div>
			<div class="${styles.Menu}">
				<div class="${styles.Inner}">
					${data?.navigation
						?.map(({ link }) => {
							const html = asHTML(link, linkResolver) || ''
							const getHref = new RegExp('href="([^"]*)')
							const href = getHref.exec(html)?.[1]
							return link
								? `<div class="${clsx(styles.Item, href === route && styles.IsActive)}">${asHTML(
										link,
										linkResolver
								  )}</div>`
								: ''
						})
						.join('')}
					${alternate
						? /* HTML */ `<div class="${clsx(styles.Item, styles.Lang)}">
								<span>${LangLabel[lang]}</span>
								/
								<a href="${getLink(alternate)}">${LangLabel[alternate.lang]}</a>
						  </div>`
						: ''}

					<button class="${styles.Close}">
						<svg viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
							<path d="M11.5002 23V0H13.0543V23H11.5002Z" />
							<path
								d="M-9.84669e-05 11.3447L24.554 11.3447L24.554 11.6555L-9.84805e-05 11.6555L-9.84669e-05 11.3447Z"
							/>
							<path d="M13.1186 11.4995L21.7998 20.1807L21.58 20.4004L12.8989 11.7193L13.1186 11.4995Z" />
							<path
								d="M3.01732 2.64207L11.6985 11.3232L11.4787 11.543L2.79754 2.86185L3.01732 2.64207Z"
							/>
							<path
								d="M11.4788 11.4995L2.79761 20.1807L3.01738 20.4004L11.6986 11.7193L11.4788 11.4995Z"
							/>
							<path
								d="M21.5803 2.64207L12.8992 11.3232L13.1189 11.543L21.8001 2.86185L21.5803 2.64207Z"
							/>
						</svg>
					</button>
				</div>
			</div>
		</header>
	`
}
