import * as prismic from '@prismicio/client'
import cookie from 'js-cookie'

import { preview_document_id_cookie, repo_name } from '../../config.json'
import { FooterProps } from '../components/Footer'
import { FormDocumentProps } from '../components/Form'
import { HeaderProps } from '../components/Header'
import { CustomType, Lang, ShortLang } from '../const'
import { Page, PageProps } from '../pages/Page'
import { getData } from '../utils/getData'

const endpoint = prismic.getRepositoryEndpoint(repo_name)
const client = prismic.createClient(endpoint)

export async function getPreview(ref: string, route?: string): Promise<string> {
	const { allPages, allHeaders, allFooters, allForms } = await getData(client, ref)
	const allDocs = [...allPages, ...allHeaders, ...allFooters, ...allForms]

	const routes = [
		...allPages.map(({ lang, uid }) => (uid === 'index' ? `/${ShortLang[lang]}/` : `/${ShortLang[lang]}/${uid}/`)),
		'/unpublished/'
	]

	let doc: PageProps | HeaderProps | FooterProps | FormDocumentProps | undefined
	let type: CustomType = CustomType.page
	let lang: Lang = Lang.de

	if (route === '/unpublished/') {
		const id = cookie.get(preview_document_id_cookie)
		doc = allDocs.find((page) => page.id === id)
		if (doc) {
			type = doc.type
			lang = doc.lang
		}
	} else {
		const routeArray = route ? route.split('/') : []
		lang = Lang[routeArray[1] as ShortLang]
		const uid = routeArray[2] || 'index'
		doc = allDocs.find((page) => page.uid === uid && page.lang === lang)
	}

	if (doc && type && route) {
		switch (type) {
			case CustomType.page: {
				const header = allHeaders.find((header) => header.lang === lang)
				const footer = allFooters.find((footer) => footer.lang === lang)
				return Page(doc as PageProps, route, routes, header, footer, allForms)
			}
		}
	}

	return ''
}
