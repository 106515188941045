import { Element, HTMLFunctionSerializer } from '@prismicio/helpers'
import { RTAnyNode } from '@prismicio/types'
import styles from '../components/Section.module.css'
import { CustomType } from '../const'

type PDFLink = {
	data?: {
		type: CustomType.pdf
		data?: {
			pdf?: {
				url: string
			}
		}
	}
}

export const textSerializer: HTMLFunctionSerializer = (
	type: string,
	node: RTAnyNode | PDFLink,
	text?: string,
	children?: string
): string | null | undefined => {
	if (type === 'label') {
		const {
			data: { label }
		} = node as { data: { label?: string } }

		if (label === 'small')
			return `
			<span class="${styles.Small}">
				${children}
			</span>
		`
		return null
	}

	const pdfNode = node as PDFLink

	if (type === Element.hyperlink && pdfNode?.data?.type === CustomType.pdf) {
		const url = pdfNode?.data?.data?.pdf?.url || '/404'
		return `<a href="${url}" target="_blank" download="true">${text}</a>`
	}

	if (type === Element.preformatted) {
		return text
	}

	return null
}
