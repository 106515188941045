import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import fadeInStyles from './FadeIn.module.css'
import inputStyles from './Input.module.css'
import styles from './InputFile.module.css'

export type InputFileProps = Slice<
	SliceType.input_file,
	{
		name: string
		label: string
		placeholder: string
		button_label: string
		required: boolean
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
	}
>

export function InputFile(props: InputFileProps): string {
	const { primary } = props
	const {
		name,
		label,
		placeholder,
		button_label,
		required = false,
		column_span_small = '6/6',
		column_span_large = '6/6'
	} = primary

	return /* HTML */ `
		<label
			class="${clsx(
				styles.Main,
				inputStyles.Main,
				fadeInStyles.Main,
				getColumnSpanClasses(column_span_small, column_span_large)
			)}"
			data-no-files-selected-text="${placeholder}"
			><span class="${clsx(styles.Label, inputStyles.Label)}">${label}${required ? '*' : ''}</span>
			<span class="${styles.DummyInput}"
				><span class="${styles.Placeholder}">${placeholder}</span
				><span class="${styles.ButtonContainer}"
					><span class="${styles.Button}">${button_label}</span></span
				></span
			>
			<input class="${styles.Input}" type="file" name="${name}" ${required ? 'required' : ''} />
		</label>
	`
}
