import { FilledImageFieldImage, RichTextField, Slice } from '@prismicio/types'
import { SliceType } from '../const'
import styles from './Section.module.css'
import fadeInStyles from './FadeIn.module.css'
import { asHTML, asText } from '@prismicio/helpers'
import { ResponsiveImage } from './ResponsiveImage'
import linkResolver from '../utils/linkResolver'
import clsx from 'clsx'
import mapMobile from '../assets/map_mobile.image.svg'
import mapDesktop from '../assets/map_desktop.image.svg'
import stageStyles from './stage/Stage.module.css'
import { textSerializer } from '../utils/textSerializer'

export type SectionProps = Slice<
	SliceType.section,
	{
		eyebrow?: string
		title?: RichTextField
		lead?: RichTextField
		text?: RichTextField
		list?: RichTextField
		links?: RichTextField
		image?: FilledImageFieldImage
		image_alignment?: 'left' | 'right'
		map?: boolean
	}
>

export function Section(props: SectionProps, first = false, lessBottomPadding = false): string {
	const {
		primary: { eyebrow, title, lead, text, list, links, image, image_alignment, map }
	} = props

	return /* HTML */ `
		<div
			class="${clsx(
				styles.Main,
				first && styles.First,
				lessBottomPadding && styles.LessBottomPadding,
				image?.url && styles.HasImage,
				map && styles.HasMap,
				image_alignment === 'right' && styles.IsRightAligned
			)}"
		>
			<div class="${styles.Header}">
				${eyebrow ? `<div class="${clsx(styles.Eyebrow, fadeInStyles.Main)}">${eyebrow}</div>` : ''}
				${title ? `<div class="${clsx(styles.Title, fadeInStyles.Main)}">${asHTML(title)}</div>` : ''}
			</div>
			${image?.url
				? `<div class="${clsx(styles.Image, stageStyles.Image, stageStyles.Wobble)}">${ResponsiveImage({
						...image,
						loading: 'eager'
				  })}</div>`
				: ''}
			<div class="${styles.Content}">
				${lead
					? `<div class="${clsx(styles.Lead, fadeInStyles.Main)}">${asHTML(
							lead,
							linkResolver,
							textSerializer
					  )}</div>`
					: ''}
				${text
					? `<div class="${clsx(styles.Text, fadeInStyles.Main)}">${asHTML(
							text,
							linkResolver,
							textSerializer
					  )}</div>`
					: ''}
				${list && asText(list).length > 0
					? `<div class="${clsx(styles.List, fadeInStyles.Main)}">${asHTML(
							list,
							linkResolver,
							textSerializer
					  )}</div>`
					: ''}
				${links && asText(links).length > 0
					? `<div class="${clsx(styles.Links, fadeInStyles.Main)}">${asHTML(
							links,
							linkResolver,
							textSerializer
					  )}</div>`
					: ''}
			</div>
			${map
				? /* HTML */ `<div class="${clsx(styles.Map, fadeInStyles.Main)}">
						<picture class="${styles.Inner}">
							<source srcset="${mapDesktop}" media="(min-width: 1024px)" />
							<img src="${mapMobile}" loading="lazy" alt="map" />
						</picture>
				  </div>`
				: ''}
		</div>
	`
}
