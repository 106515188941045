import { FilledContentRelationshipField, Slice } from '@prismicio/types'
import { CustomType, Lang, SliceType } from '../const'
import styles from './Anchor.module.css'

export type AnchorProps = Slice<
	SliceType.anchor,
	{
		anchor?: FilledContentRelationshipField<CustomType.anchor, Lang, {}>
	}
>

export function Anchor(props: AnchorProps): string {
	const {
		primary: { anchor }
	} = props

	return /* HTML */ `<div class="${styles.Main}" data-id="${anchor?.uid}"></div>`
}
