import { EventEmitter } from './EventEmitter'

export class Router extends EventEmitter {
	private readonly routes: string[] = []
	public activeRoute: string

	constructor() {
		super()
		this.routes = window.__ROUTES__
		this.activeRoute = window.location.pathname
		window.history.scrollRestoration = 'manual'
		window.addEventListener('popstate', this.onPopState.bind(this))
	}

	push(route: string): void {
		const { pathname, search, hash } = new URL(`${window.location.origin}${route}`)

		const newRoute = this.routes.includes(pathname)
			? `${pathname}${search}${hash}`
			: `/${document.documentElement.lang}/404/`

		if (newRoute === this.activeRoute) {
			return
		}

		this.activeRoute = newRoute
		window.history.pushState({}, this.activeRoute, `${window.location.origin}${this.activeRoute}`)
		this.emit('change', this.activeRoute)
	}

	onPopState(): void {
		const url = this.routes.includes(window.location.pathname)
			? window.location.pathname
			: `/${document.documentElement.lang}/404/`

		if (url === this.activeRoute) {
			return
		}

		this.activeRoute = url
		this.emit('change', this.activeRoute)
	}
}
