import { asHTML } from '@prismicio/helpers'
import { RichTextField, Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import fadeInStyles from './FadeIn.module.css'
import styles from './FieldDescription.module.css'
import inputStyles from './Input.module.css'

export type FieldDescriptionProps = Slice<
	SliceType.field_description,
	{
		text: RichTextField
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
	}
>

export function FieldDescription(props: FieldDescriptionProps): string {
	const { primary } = props
	const { text, column_span_small = '6/6', column_span_large = '6/6' } = primary
	const textAsHTML = asHTML(text)
	const hasFakelabel = column_span_small !== '6/6' || column_span_large !== '6/6'

	return /* HTML */ `${textAsHTML
		? `<div class="${clsx(
				styles.Main,
				fadeInStyles.Main,
				getColumnSpanClasses(column_span_small, column_span_large),
				hasFakelabel && inputStyles.Main
		  )}">${
				hasFakelabel
					? /* HTML */ `<span class="${clsx(styles.FakeLabel, inputStyles.Label)}">hidden</span>`
					: ''
		  }<div class="${clsx(styles.Inner, hasFakelabel && styles.HasFakeLabel)}">${textAsHTML}</div></div>`
		: ''} `
}
