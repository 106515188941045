import { RichTextField } from '@prismicio/types'
import styles from './Info.module.css'
import { asHTML } from '@prismicio/helpers'
import clsx from 'clsx'
import fadeInStyles from './FadeIn.module.css'
import linkResolver from '../utils/linkResolver'
import { textSerializer } from '../utils/textSerializer'

export type InfoProps = {
	eyebrow?: string
	title?: RichTextField
	text?: RichTextField
}

export function Info(props: InfoProps): string {
	const { eyebrow, title, text } = props
	return /* HTML */ `<div class="${clsx(styles.Main, fadeInStyles.Main)}">
		${eyebrow ? `<div class="${styles.Eyebrow}">${eyebrow}</div>` : ''}
		${title ? `<div class="${styles.Title}">${asHTML(title)}</div>` : ''}
		${text ? `<div class="${styles.Text}">${asHTML(text, linkResolver, textSerializer)}</div>` : ''}
	</div>`
}
