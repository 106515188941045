import { Controller } from '../const'
import styles from './Slider.module.css'
import { Carousel } from './Carousel'

export class SliderController implements Controller {
	private readonly carousel: Carousel | null = null

	constructor(node: HTMLElement) {
		if (window.matchMedia('(pointer:fine)').matches) {
			const container = node.querySelector<HTMLDivElement>(`.${styles.Items}`)
			if (container) {
				this.carousel = new Carousel(container)
			}
		}
	}

	resize(): void {
		this.carousel?.onResize()
	}

	dispose() {
		this.carousel?.dispose()
	}
}
