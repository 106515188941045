import { RichTextField, Slice } from '@prismicio/types'
import { SliceType } from '../const'
import styles from './Quote.module.css'
import { asHTML } from '@prismicio/helpers'
import clsx from 'clsx'
import fadeInStyles from './FadeIn.module.css'

export type QuoteProps = Slice<
	SliceType.quote,
	{
		author?: string
		quote?: RichTextField
	}
>

export function Quote(props: QuoteProps, noPadding = false): string {
	const {
		primary: { author, quote }
	} = props

	return /* HTML */ `<div class="${clsx(styles.Main, noPadding && styles.NoPadding)}">
		${author ? `<div class="${clsx(styles.Author, fadeInStyles.Main)}">${author}</div>` : ''}
		${quote ? `<blockquote class="${clsx(styles.Quote, fadeInStyles.Main)}">${asHTML(quote)}</blockquote>` : ''}
	</div>`
}
