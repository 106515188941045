import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import fadeInStyles from './FadeIn.module.css'
import inputStyles from './Input.module.css'
import styles from './InputSelect.module.css'

export type InputSelectProps = Slice<
	SliceType.input_select,
	{
		name: string
		label: string
		placeholder: string
		selected_option: string
		required: boolean
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
		max_number: number
	}
>

export function InputSelect(
	props: InputSelectProps,
	optionalData?: { importantSelectedOption?: string; importantSelectMaxNumber?: number }
): string {
	const { primary, items } = props
	const { name, label, placeholder, required = false, column_span_small = '6/6', column_span_large = '6/6' } = primary

	const selected_option = optionalData?.importantSelectedOption ?? primary.selected_option
	const max_number = optionalData?.importantSelectMaxNumber ?? primary.max_number ?? 1

	const numbers = Array.from({ length: max_number }, (_, i) => i + 1)

	return /* HTML */ `
		<label
			class="${clsx(
				styles.Main,
				inputStyles.Main,
				fadeInStyles.Main,
				getColumnSpanClasses(column_span_small, column_span_large)
			)}"
			><span class="${inputStyles.Label}">${label}${required ? '*' : ''}</span>
			<select class="${clsx(styles.Input, inputStyles.Input)}" name="${name}" ${required ? 'required' : ''}>
				${placeholder ? `<option value="">${placeholder}</option>` : ''}
				${items && items.length > 0
					? items
							.map(
								({ option }) =>
									`<option ${option === selected_option ? 'selected' : ''}>${option}</option>`
							)
							.join('')
					: numbers
							.map(
								(number) =>
									`<option ${
										number.toString() === selected_option ? 'selected' : ''
									}>${number}</option>`
							)
							.join('')}
			</select>
		</label>
	`
}
