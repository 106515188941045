import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { ColumnSpan, SliceType } from '../const'
import { getColumnSpanClasses } from '../utils/getColumnSpanClasses'
import fadeInStyles from './FadeIn.module.css'
import inputStyles from './Input.module.css'
import styles from './InputTextarea.module.css'

export type InputTextareaProps = Slice<
	SliceType.input_textarea,
	{
		name: string
		label: string
		placeholder: string
		required: boolean
		rows: number
		column_span_small: ColumnSpan
		column_span_large: ColumnSpan
	}
>

export function InputTextarea(props: InputTextareaProps): string {
	const { primary } = props
	const {
		name,
		label,
		placeholder,
		required = false,
		rows = 5,
		column_span_small = '6/6',
		column_span_large = '6/6'
	} = primary

	return /* HTML */ `
		<label
			class="${clsx(
				styles.Main,
				inputStyles.Main,
				fadeInStyles.Main,
				getColumnSpanClasses(column_span_small, column_span_large)
			)}"
			><span class="${inputStyles.Label}">${label}${required ? '*' : ''}</span>
			<textarea
				class="${clsx(styles.Input, inputStyles.Input)}"
				name="${name}"
				placeholder="${placeholder}"
				${required ? 'required' : ''}
				rows="${rows}"
			></textarea>
		</label>
	`
}
