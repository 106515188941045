import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { Display, SliceType } from '../const'
import fadeInStyles from './FadeIn.module.css'
import styles from './InputRadio.module.css'

export type InputRadioProps = Slice<
	SliceType.input_radio,
	{
		name: string
		required: boolean
		display: Display
	},
	{
		label: string
	}
>

export function InputRadio(props: InputRadioProps): string {
	const { primary, items } = props
	const { name, required = false, display = 'inline' } = primary

	return /* HTML */ `
		<fieldset class="${clsx(styles.Main, fadeInStyles.Main, display === 'grid' && styles.Grid)}">
			<legend class="${styles.Legend}">${name.charAt(0).toUpperCase() + name.slice(1)}</legend>

			${items
				? items
						.map((item, index) => {
							const { label } = item
							return /* HTML */ `<label class="${styles.Label}">
								<svg class="${styles.Svg}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<circle class="${styles.PathCircle}" cx="256" cy="256" r="195.765" />
									<path
										d="M256 0C114.71 0 0 114.71 0 256c0 141.29 114.71 256 256 256 141.29 0 256-114.71 256-256C512 114.71 397.29 0 256 0Zm0 15.059c132.979 0 240.941 107.962 240.941 240.941 0 132.979-107.962 240.941-240.941 240.941-132.979 0-240.941-107.962-240.941-240.941C15.059 123.021 123.021 15.059 256 15.059Z"
									/>
								</svg>
								<span class="${styles.LabelText}">${label}</span>
								<input
									class="${styles.Input}"
									type="radio"
									name="${name}"
									value="${label.toLowerCase()}"
									${index === 0 ? 'checked' : ''}
									${index === 0 && required ? 'required' : ''}
								/>
							</label>`
						})
						.join('')
				: ''}
		</fieldset>
	`
}
