import { Slice } from '@prismicio/types'
import clsx from 'clsx'

import { SliceType, Spacing } from '../const'
import styles from './FieldSeparator.module.css'
import fadeInStyles from './FadeIn.module.css'

export type FieldSeparatorProps = Slice<
	SliceType.field_separator,
	{
		show_line: boolean
		spacing: Spacing
	}
>

export function FieldSeparator(props: FieldSeparatorProps): string {
	const { primary } = props
	const { show_line = true, spacing = 'none' } = primary

	return /* HTML */ `
		<div
			class="${clsx(
				styles.Main,
				fadeInStyles.Main,
				show_line && styles.ShowLine,
				spacing === 'small' && styles.SpacingSmall,
				spacing === 'medium' && styles.SpacingMedium,
				spacing === 'large' && styles.SpacingLarge
			)}"
		></div>
	`
}
