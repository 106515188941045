import { Controller } from '../const'
import styles from './Image.module.css'

export class ImageController implements Controller {
	private readonly image: HTMLImageElement | null

	constructor(node: HTMLElement) {
		this.image = node.querySelector(`.${styles.Image}`)
		this.image?.addEventListener('load', this.onLoad)
	}

	onLoad() {
		this.image?.removeEventListener('load', this.onLoad)
		this.image?.classList.add(styles.IsLoaded)
	}

	dispose() {
		this.image?.removeEventListener('load', this.onLoad)
	}
}
