import { FilledContentRelationshipField, GroupField, RichTextField, Slice } from '@prismicio/types'
import { CustomType, Lang, SliceType } from '../const'
import styles from './Slider.module.css'
import { asHTML } from '@prismicio/helpers'
import clsx from 'clsx'
import fadeInStyles from './FadeIn.module.css'
import linkResolver from '../utils/linkResolver'

export type SliderProps = Slice<
	SliceType.slider,
	{
		slider?: FilledContentRelationshipField<
			CustomType.slider,
			Lang,
			{
				eyebrow?: string
				title?: RichTextField
				items?: GroupField<{
					item_title?: RichTextField
					subline?: string
					text?: RichTextField
				}>
			}
		>
	}
>

export function Slider(props: SliderProps): string {
	if (!props.primary.slider?.data) return ''

	const { eyebrow, title, items = [] } = props.primary.slider.data

	return /* HTML */ `<div class="${styles.Main}">
		<div class="${styles.Header}">
			${eyebrow ? `<div class="${clsx(styles.Eyebrow, fadeInStyles.Main)}">${eyebrow}</div>` : ''}
			${title ? `<div class="${clsx(styles.Title, fadeInStyles.Main)}">${asHTML(title)}</div>` : ''}
		</div>
		<div class="${styles.Items}">
			${items
				.map((item) => {
					const { item_title, subline, text } = item
					return /* HTML */ `<div class="${clsx(styles.Item, fadeInStyles.Main)}">
						${item_title ? `<div class="${styles.ItemTitle}">${asHTML(item_title)}</div>` : ''}
						${subline ? `<div class="${styles.Subline}">${subline}</div>` : ''}
						${text ? `<div class="${styles.Text}">${asHTML(text, linkResolver)}</div>` : ''}
					</div>`
				})
				.join('')}
		</div>
	</div>`
}
