import { Controller } from '../const'
import { clamp } from '../utils/clamp'
import { App } from '../app/App'
import { delay } from '../utils/delay'

export class FadeInController implements Controller {
	private top = 0
	private offset = 0
	private targetOffset = 0
	private enabled = false

	constructor(private readonly node: HTMLElement, private readonly app: App) {
		this.node.style.opacity = '0'
	}

	async show() {
		await delay(750)
		this.enabled = true
	}

	resize() {
		this.top = this.node.getBoundingClientRect().top + this.app.scrollY
		this.scroll()
	}

	scroll(): void {
		const top = this.top - this.app.scrollY
		if (top < 0 || top > this.app.windowHeight) return
		this.targetOffset = clamp((1 - top / this.app.windowHeight) * 2, 0, 1)
	}

	update() {
		if (!this.enabled) return

		this.offset += (this.targetOffset - this.offset) * 0.05

		if (this.offset >= 0 && this.offset <= 1 && Math.abs(this.offset - this.targetOffset) > 0.001) {
			this.node.style.transform = `translateY(${(1 - this.offset) * this.app.windowHeight * 0.15}px)`
			this.node.style.opacity = `${this.offset}`
		}
	}
}
