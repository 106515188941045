import { FilledImageFieldImage, RichTextField, Slice } from '@prismicio/types'
import { SliceType } from '../const'
import styles from './Gallery.module.css'
import { asHTML } from '@prismicio/helpers'
import { ResponsiveImage } from './ResponsiveImage'

export type GalleryProps = Slice<
	SliceType.gallery,
	{
		eyebrow?: string
		title?: RichTextField
		subline?: string
	},
	{
		image?: FilledImageFieldImage
		job?: string
		name?: string
		phone?: string
		email?: string
	}
>

export function Gallery(props: GalleryProps): string {
	const {
		primary: { eyebrow, title, subline },
		items = []
	} = props

	return /* HTML */ `<div class="${styles.Main}">
		<div class="${styles.Header}">
			${eyebrow ? `<div class="${styles.Eyebrow}">${eyebrow}</div>` : ''}
			${title ? `<div class="${styles.Title}">${asHTML(title)}</div>` : ''}
			${subline ? `<div class="${styles.Subline}">${subline}</div>` : ''}
		</div>
		<div class="${styles.Items}">
			${items
				.map((item) => {
					const { image, job, name, phone, email } = item
					return /* HTML */ `<div class="${styles.Item}">
						${image?.url ? `<div class="${styles.Image}">${ResponsiveImage(image)}</div>` : ''}
						${job ? `<div class="${styles.Job}">${job}</div>` : ''}
						${name
							? email
								? `<a href="mailto:${email}" class="${styles.Name}">${name}</a>`
								: `<div class="${styles.Name}">${name}</div>`
							: ''}
						${phone ? `<span class="${styles.Phone}">${phone}</span>` : ''}
					</div>`
				})
				.join('')}
		</div>
	</div>`
}
