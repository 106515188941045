import { Client } from '@prismicio/client'

import { FooterProps } from '../components/Footer'
import { FormDocumentProps } from '../components/Form'
import { HeaderProps } from '../components/Header'
import { CustomType } from '../const'
import { PageProps } from '../pages/Page'

export async function getData(
	client: Client,
	ref?: string
): Promise<{
	allPages: PageProps[]
	allHeaders: HeaderProps[]
	allFooters: FooterProps[]
	allForms: FormDocumentProps[]
}> {
	const [allPages, allHeaders, allFooters, allForms] = await Promise.all([
		// @ts-ignore
		client.getAllByType<PageProps>(CustomType.page, {
			ref,
			lang: '*',
			fetchLinks: ['slider.eyebrow', 'slider.title', 'slider.items', 'accordion.body', 'pdf.pdf']
		}),
		// @ts-ignore
		client.getAllByType<HeaderProps>(CustomType.header, { ref, lang: '*' }),
		// @ts-ignore
		client.getAllByType<FooterProps>(CustomType.footer, { ref, lang: '*', fetchLinks: ['pdf.pdf'] }),
		// @ts-ignore
		client.getAllByType<FormDocumentProps>(CustomType.form, {
			ref,
			lang: '*'
		})
	])

	return {
		allPages,
		allHeaders,
		allFooters,
		allForms
	}
}
