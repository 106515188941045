import { CustomType, Lang } from '../const'
import { LinkResolverFunction } from '@prismicio/helpers'
import { getLink } from './getLink'

const linkResolver: LinkResolverFunction = (doc): string => {
	const { uid, type, lang } = doc
	if (!uid) return '/'
	return getLink({ type: type as CustomType, lang: lang as Lang, uid })
}

export default linkResolver
