import { Mesh, Object3D, PlaneGeometry, ShaderMaterial, Texture, Vector3 } from 'three'
import vertexShader from './image.vert'
import fragmentShader from './image.frag'
import animateFragmentShader from './animatedImage.frag'

type Props = {
	parent: Object3D
	z?: number
	y?: number
	scale?: number
	flip?: boolean
	animate?: boolean
	size?: number
	strength?: number
	speed?: number
	float?: boolean
	hide?: boolean
}

export class IntroPlane {
	private readonly mesh: Mesh<PlaneGeometry, ShaderMaterial>
	private readonly material: ShaderMaterial
	private readonly scale: number
	private readonly flip: boolean
	private readonly random: number
	private readonly float: boolean

	public readonly position: Vector3

	constructor(options: Props) {
		const {
			parent,
			z = 0,
			y = 0,
			scale = 1,
			flip = false,
			animate = false,
			float = false,
			size = 20,
			strength = 0.005,
			speed = 0.00005,
			hide
		} = options

		this.random = Math.random()
		this.scale = scale
		this.flip = flip
		this.float = float

		this.material = new ShaderMaterial({
			transparent: true,
			vertexShader,
			fragmentShader: animate ? animateFragmentShader : fragmentShader,
			uniforms: {
				time: { value: performance.now() },
				size: { value: size },
				strength: { value: strength },
				speed: { value: speed },
				map: { value: new Texture() }
			}
		})

		this.mesh = new Mesh(new PlaneGeometry(scale, scale), this.material)
		this.mesh.position.z = z
		this.mesh.position.y = y
		this.position = new Vector3(0, y, z)

		if (!hide) {
			parent.add(this.mesh)
		}
	}

	setTexture(texture: Texture) {
		this.material.uniforms.map.value = texture
	}

	resize(filmHeight: number, focalLength: number, cameraZ: number, aspect: number) {
		const distance = cameraZ - this.mesh.position.z

		const scaleY = (distance * filmHeight) / focalLength
		const scaleX = scaleY * aspect
		const scale = Math.max(scaleX, scaleY)

		this.mesh.scale.x = scale * 1.1 * (this.flip ? -1 : 1)
		this.mesh.scale.y = scale * 1.1
	}

	update(time: number) {
		this.material.uniforms.time.value = time
		this.mesh.position.copy(this.position)

		if (this.float) {
			this.mesh.position.x += Math.sin((time + this.random * 1000) * 0.001) * 0.0005
			this.mesh.position.y += Math.sin((time + this.random * 1000) * 0.001) * 0.0005
		}
	}
}
