import { PrismicDocumentWithUID, SliceZone } from '@prismicio/types'
import { LinkField } from '@prismicio/types/src/value/link'

import { Accordion, AccordionProps } from '../components/Accordion'
import { Anchor, AnchorProps } from '../components/Anchor'
import { Columns, ColumnsProps } from '../components/Columns'
import { Footer, FooterProps } from '../components/Footer'
import { Form, FormDocumentProps, FormSliceProps } from '../components/Form'
import { Gallery, GalleryProps } from '../components/Gallery'
import { Header, HeaderProps } from '../components/Header'
import { Image, ImageProps } from '../components/Image'
import { ImagePair, ImagePairProps } from '../components/ImagePair'
import { Intro, IntroProps } from '../components/intro/Intro'
import { PageNavigation, PageNavigationProps } from '../components/PageNavigation'
import { Quote, QuoteProps } from '../components/Quote'
import { Section, SectionProps } from '../components/Section'
import { Slider, SliderProps } from '../components/Slider'
import { CustomType, Lang, SliceType } from '../const'
import { getLink } from '../utils/getLink'
import styles from './Page.module.css'

export type PageProps = PrismicDocumentWithUID<
	{
		page_title?: string
		background_color?: 'rose' | 'green' | 'grey'
		redirect?: LinkField
		body?: SliceZone<
			| ImageProps
			| SectionProps
			| IntroProps
			| ImagePairProps
			| QuoteProps
			| GalleryProps
			| ColumnsProps
			| AccordionProps
			| SliderProps
			| PageNavigationProps
			| AnchorProps
			| FormSliceProps
		>
	},
	CustomType,
	Lang
>

export function Page(
	props: PageProps,
	route: string,
	routes: string[],
	header?: HeaderProps,
	footer?: FooterProps,
	forms?: FormDocumentProps[]
): string {
	const { alternate_languages, data: { background_color, body = [] } = {} } = props

	return /* HTML */ `
		<main
			class="${styles.Main}"
			data-background="${background_color}"
			data-animate="${body[0]?.slice_type === SliceType.intro}"
		>
			${body.length
				? /* HTML */ `<div class="${styles.Inner}">
						${body
							.map((slice, index) => {
								switch (slice.slice_type) {
									case SliceType.image: {
										return Image(slice)
									}
									case SliceType.section: {
										return Section(
											slice,
											index === 0,
											body[index + 1]?.slice_type === SliceType.columns
										)
									}
									case SliceType.intro: {
										return Intro()
									}
									case SliceType.image_pair: {
										return ImagePair(
											slice,
											body[index - 1]?.slice_type === SliceType.image ||
												body[index - 1]?.slice_type === SliceType.accordion
										)
									}
									case SliceType.quote: {
										return Quote(slice, body[index - 1]?.slice_type === SliceType.image_pair)
									}
									case SliceType.gallery: {
										return Gallery(slice)
									}
									case SliceType.columns: {
										return Columns(slice)
									}
									case SliceType.accordion: {
										return Accordion(slice)
									}
									case SliceType.slider: {
										return Slider(slice)
									}
									case SliceType.anchor: {
										return Anchor(slice)
									}
									case SliceType.form: {
										const form = forms?.find((form) => slice.primary.form?.id === form.id)
										const successPage = slice.primary.success_page
										const redirectPath = successPage
											? getLink({
													uid: successPage.uid,
													type: successPage.type as CustomType,
													lang: successPage.lang as Lang
											  })
											: ''
										const preFilledValue = slice.primary.prefilled_value
										const preFilledDate = slice.primary.prefilled_date
										const importantSelectedOption = slice.primary.important_selected_option
										const importantSelectMaxNumber = slice.primary.important_select_max_number
										const optionalData = {
											redirectPath,
											preFilledValue,
											preFilledDate,
											importantSelectedOption,
											importantSelectMaxNumber
										}
										if (form) return Form(form, optionalData)
									}
								}
							})
							.join('')}
						${footer ? Footer(footer) : ''}
						${body
							.map((slice) => {
								if (slice.slice_type === SliceType.page_navigation) {
									return PageNavigation(slice)
								}
							})
							.join('')}
				  </div>`
				: ''}
			${header ? Header({ ...header, alternate: alternate_languages[0], route }) : ''}
		</main>
	`
}
