import { LoadingManager, Scene, Vector2, WebGLRenderer } from 'three'

import { App } from '../../app/App'
import { Images } from './Images'
import { Intro } from './Intro'
import styles from './Stage.module.css'

export class Stage {
	private readonly scene: Scene
	private readonly intro: Intro
	private readonly images: Images
	private readonly progress: HTMLElement
	private readonly percentage: HTMLElement

	private isFixed = false
	private loaded = false

	public readonly pointer = new Vector2()
	public readonly renderer: WebGLRenderer
	public width = 0
	public height = 0
	public loadingManager: LoadingManager

	constructor(
		private readonly container: HTMLElement,
		private readonly loader: HTMLElement,
		private readonly app: App
	) {
		this.progress = this.loader.querySelector(`.${styles.Progress}`) as HTMLElement
		this.percentage = this.loader.querySelector(`.${styles.Percentage}`) as HTMLElement
		this.loadingManager = new LoadingManager()
		this.scene = new Scene()
		this.renderer = new WebGLRenderer({
			antialias: window.devicePixelRatio < 2,
			alpha: true,
			powerPreference: 'high-performance'
		})
		this.renderer.setClearAlpha(0)
		this.renderer.autoClear = false
		this.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
		this.container.appendChild(this.renderer.domElement)

		this.intro = new Intro(this, app)
		this.images = new Images(this, app)

		const { width, height } = this.container?.getBoundingClientRect()
		this.width = width
		this.height = height

		this.loadingManager.onProgress = this.onProgress.bind(this)

		document.addEventListener('pointermove', this.onPointerMove.bind(this))
	}

	async load(showLoader = false): Promise<void> {
		if (showLoader) {
			this.loader.style.setProperty('display', 'grid')
		}
		await Promise.all([this.intro.load(), this.images.load()])
		this.loaded = true
	}

	async show(animate = false): Promise<void> {
		this.loader.style.setProperty('display', 'none')
		return this.intro.show(animate)
	}

	async hide(): Promise<void> {
		this.loader.style.setProperty('display', 'none')
		await Promise.all([this.intro.hide(), this.images.hide()])
	}

	onProgress(url: string, loaded: number, total: number): void {
		const progress = loaded / total
		this.progress.style.setProperty('transform', `scaleX(${progress})`)
		this.percentage.textContent = `${Math.round(progress * 100)}%`
	}

	setIntroNode(node: HTMLElement): void {
		this.intro.setNode(node)
	}

	setImageNodes(nodes: HTMLElement[]): void {
		this.images.setImageNodes(nodes)
	}

	onPointerMove(event: PointerEvent): void {
		this.pointer.set(event.x, event.y)
	}

	scroll(): void {
		this.intro.scroll()
		this.images.scroll()
	}

	resize(): void {
		const { width, height } = this.container?.getBoundingClientRect()

		this.width = width
		this.height = height
		this.renderer.setSize(width, height)

		this.intro.resize()
		this.images.resize()
	}

	setFixed(): void {
		this.isFixed = true
		this.container.style.transform = `translateY(0px)`
	}

	unsetFixed(): void {
		this.isFixed = false
		this.container.style.transform = `translateY(${this.app.scrollY}px)`
	}

	dispose(): void {
		this.intro.dispose()
		this.images.dispose()
	}

	update(time: number): void {
		if (!this.isFixed) {
			this.container.style.transform = `translateY(${this.app.scrollY}px)`
		}

		if (this.loaded) {
			this.renderer.clear()
			this.intro.update(time)
			this.images.update(time)
		}
	}
}
