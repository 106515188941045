import { Controller } from '../const'
import styles from './Accordion.module.css'
import { Carousel } from './Carousel'
import { Easing, Group, Tween } from '@tweenjs/tween.js'

class AccordionItemController implements Controller {
	private readonly header: HTMLElement
	private readonly body: HTMLElement
	private readonly carousel: Carousel | null = null
	private readonly group: Group = new Group()
	private open = false

	constructor(private readonly node: HTMLElement) {
		this.onClick = this.onClick.bind(this)
		this.header = node.querySelector(`.${styles.Header}`) as HTMLElement
		this.body = node.querySelector(`.${styles.Body}`) as HTMLElement

		this.header?.addEventListener('click', this.onClick)

		if (window.matchMedia('(pointer:fine)').matches) {
			const container = node.querySelector<HTMLDivElement>(`.${styles.Group}`)
			if (container) {
				this.carousel = new Carousel(container)
			}
		}
	}

	onClick(): void {
		this.group.removeAll()
		if (!this.open) {
			this.open = true

			new Tween({ opacity: 0, y: -5 }, this.group)
				.to({ opacity: 1, y: 0 }, 600)
				.easing(Easing.Cubic.Out)
				.onStart(() => {
					this.node.classList.add(styles.Open)
					this.body.style.setProperty('opacity', '0')
					this.body.style.setProperty('display', 'block')
				})
				.onUpdate(({ opacity, y }) => {
					this.body.style.setProperty('transform', `translateY(${y}vmin)`)
					this.body.style.setProperty('opacity', `${opacity}`)
				})
				.onComplete(() => {
					this.body.style.removeProperty('opacity')
					this.carousel?.onResize()
				})
				.start()
		} else {
			this.open = false
			this.node.classList.remove(styles.Open)
			this.body.style.removeProperty('display')
		}
	}

	resize() {
		this.carousel?.onResize()
	}

	update(time: number) {
		this.group.update(time)
	}

	dispose() {
		this.group.removeAll()
		this.header?.removeEventListener('click', this.onClick)
	}
}

export class AccordionController implements Controller {
	private readonly accordions: AccordionItemController[]

	constructor(private readonly node: HTMLElement) {
		this.accordions = Array.from<HTMLDivElement>(node.querySelectorAll(`.${styles.AccordionItem}`)).map(
			(node) => new AccordionItemController(node)
		)
	}

	resize() {
		this.accordions.forEach((accordion) => accordion.resize())
	}

	dispose() {
		this.accordions.forEach((accordion) => accordion.dispose())
	}

	update(time: number) {
		this.accordions.forEach((accordion) => accordion.update(time))
	}
}
